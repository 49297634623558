import { ICalendarEventInvitation } from '@/types/calendar-event-invitation/calendar-event-invitation';
import { Box, Button, HStack, Tag } from '@chakra-ui/react';
import { t } from 'i18next';
import { useInvitationActionsController } from './invitation-actions.controller';

interface IProps {
	invitation: ICalendarEventInvitation;
}

export default function InvitationActions(props: IProps) {
	const { isLoading, handleAcceptInvitationClick, handleRejectInvitationClick } = useInvitationActionsController(props);

	switch (props.invitation.invitedAttendee.status) {
		case 'DECLINED':
			return (
				<Box mt="auto" display="flex" justifyContent="center">
					<Tag size="lg" colorScheme="red">
						Invitation rejected.
					</Tag>
				</Box>
			);

		case 'ACCEPTED':
			return (
				<Box mt="auto" display="flex" justifyContent="center">
					<Tag size="lg" colorScheme="green">
						Invitation accepted.
					</Tag>
				</Box>
			);

		case 'NEEDS_ACTION':
			return (
				<HStack mt="auto" gap={4}>
					<Button
						isLoading={isLoading}
						w="full"
						variant="outline"
						colorScheme="primary"
						onClick={handleRejectInvitationClick}
					>
						{t('Decline')}
					</Button>

					<Button isLoading={isLoading} w="full" colorScheme="primary" onClick={handleAcceptInvitationClick}>
						{t('Accept')}
					</Button>
				</HStack>
			);
	}
}
