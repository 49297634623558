import ILoginProvider from '@/types/ILoginProvider';
import firebaseService from './firebase.service';

export interface AuthService {
	login: typeof login;
	logout: () => void;
	isTokenExpired: () => Promise<boolean>;
}

async function login(provider: ILoginProvider) {
	return firebaseService.login(provider);
}

function logout() {
	return firebaseService.logout();
}

export default {
	login,
	logout,
};
