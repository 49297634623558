import { ILocale } from '@/constants/locales';
import calendarService from '@/services/calendar/calendar.service';
import dateService from '@/services/date/date.service';
import ITimeZone from '@/types/ITimeZone';
import { Box, FormControl, FormLabel, IconButton, Select, Text } from '@chakra-ui/react';
import { DateTime } from 'luxon';
import { BsChevronLeft, BsChevronRight } from 'react-icons/bs';

interface IProps {
	from: DateTime;
	maxUnitsPerBooking?: number;
	onDayChange: (v: DateTime) => void;
	onNextClick: () => void;
	onPrevClick: () => void;
	onUnitsChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
	selectedDay: DateTime;
	timeZone: ITimeZone;
	unitsLabel?: string;
	value?: number;
	locale: ILocale;
}

export default function WeekGridDayView(props: IProps) {
	return (
		<Box>
			{props.maxUnitsPerBooking ? (
				<FormControl id="units" size="xs" p={2} borderBottomColor="gray.100" borderBottomWidth={1}>
					<FormLabel size="sm">{props.unitsLabel}</FormLabel>
					<Select size="sm" value={props.value} onChange={props.onUnitsChange}>
						{_generateOptions(props.maxUnitsPerBooking)}
					</Select>
				</FormControl>
			) : null}
			<Box
				borderBottomColor="gray.100"
				borderBottomWidth={1}
				display="grid"
				gridTemplateColumns="repeat(9, 1fr)"
				alignItems="center"
			>
				<IconButton
					borderRadius="none"
					h="full"
					size="xs"
					variant="ghost"
					onClick={props.onPrevClick}
					aria-label="prev week"
					icon={<BsChevronLeft />}
				/>
				{[0, 1, 2, 3, 4, 5, 6].map((days) => {
					const d = props.from.plus({ days });
					const isSelected = dateService.isSameDay(d.toJSDate(), props.selectedDay.toJSDate(), props.timeZone);

					return (
						<Box
							key={d.toISODate()}
							as="button"
							onClick={() => props.onDayChange(d)}
							textAlign="center"
							px={2}
							py={4}
							color={isSelected ? 'primary.500' : 'black'}
						>
							<Text fontSize="xx-small" textTransform="uppercase">
								{calendarService.formatDate({
									date: d.toJSDate(),
									locale: props.locale,
									options: { weekday: 'short', timeZone: props.timeZone },
								})}
							</Text>
							<Text fontSize="sm" fontWeight={600}>
								{calendarService.formatDate({
									date: d.toJSDate(),
									locale: props.locale,
									options: { day: '2-digit', timeZone: props.timeZone },
								})}
							</Text>
						</Box>
					);
				})}
				<IconButton
					borderRadius="none"
					h="full"
					size="xs"
					variant="ghost"
					onClick={props.onNextClick}
					aria-label="next week"
					icon={<BsChevronRight />}
				/>
			</Box>
		</Box>
	);
}

function _generateOptions(range: number) {
	const options = [];
	for (let i = 1; i <= range; i++) {
		options.push(
			<option key={i} value={i}>
				{i}
			</option>
		);
	}
	return options;
}
