import calendarService from '@/services/calendar/calendar.service';
import { Text } from '@chakra-ui/react';
import { DateTime } from 'luxon';

interface IHeaderProps {
	firstDayOffTheMonth: DateTime;
	lang: string;
}

export default function Header(props: IHeaderProps) {
	const days = [props.firstDayOffTheMonth];

	for (let i = 1; i < 7; i++) {
		days.push(days[i - 1].plus({ days: 1 }));
	}

	return (
		<>
			{days.map((day) => (
				<Text
					key={day.weekday}
					fontFamily="Inter Variable"
					textTransform="capitalize"
					fontWeight="500"
					fontSize="sm"
					textAlign="center"
					color="black"
				>
					{calendarService.formatDate({
						date: day.toJSDate(),
						locale: props.lang,
						options: {
							weekday: 'short',
						},
					})}
				</Text>
			))}
		</>
	);
}
