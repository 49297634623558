import LOCALES, { ILocale } from '@/constants/locales';
import { FormControl } from '@chakra-ui/react';
import { Select, SizeProp } from 'chakra-react-select';
import { t } from 'i18next';

interface IProps {
	onChange: (args: { label: string; value: ILocale }) => void;
	label: string;
	value: ILocale;
	variant?: string;
	size?: SizeProp | 'xs';
	availableValues?: Array<ILocale>;
}

const options = Object.keys(LOCALES).map((k) => {
	return { value: k as ILocale, label: LOCALES[k as ILocale] };
});

export function LanguageSelect(props: IProps) {
	const value = { value: props.value, label: LOCALES[props.value as ILocale] };
	const filteredOptions = options.filter((option) =>
		props.availableValues ? props.availableValues.includes(option.value) : true
	);

	if (!filteredOptions.length) {
		return null;
	}

	return (
		<FormControl>
			<Select
				variant={props.variant}
				placeholder={t('Language')}
				aria-label={props.label}
				size={props.size as any}
				options={filteredOptions}
				value={value}
				onChange={(v) => {
					if (!v) {
						return;
					}
					props.onChange({ label: v.label, value: v.value });
				}}
			/>
		</FormControl>
	);
}
