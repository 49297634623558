import AppLoader from '@/components/AppLoader';
import { TimeTimeBanner } from '@/components/timetime-banner/timetime-banner.component';
import { Avatar, Box, ChakraProvider, Heading, Text } from '@chakra-ui/react';
import { keyframes } from '@emotion/react';
import { t } from 'i18next';
import { FiChevronLeft } from 'react-icons/fi';
import { useBookingFormPage } from './booking-form.controller';
import BookingForm from './components/BookingForm/BookingForm';
import NoAvailabilityInfo from './components/no-availability-info/no-availability-info.view';

const animationKeyframes = keyframes`0% { transform: opacity: 0 } 100% { opacity: 1 }`;
const animation = `${animationKeyframes} .2s ease-in-out forwards`;

export default function BookingFormPage() {
	const {
		handleBookingCreated,
		onBackClick,
		appConfig,
		end,
		hasAvailability,
		id,
		locale,
		price,
		privacyPolicyUrl,
		query,
		questions,
		start,
		theme,
		timeZone,
	} = useBookingFormPage();

	if (!id) {
		return <Text> No id </Text>;
	}

	if (!query.data) {
		return (
			<Box h="100dvh" p="4" bg="white" overflow="auto">
				<AppLoader />
			</Box>
		);
	}

	return (
		<ChakraProvider theme={theme}>
			<Box
				opacity={0}
				animation={animation}
				h="100dvh"
				bg="white"
				overflow="auto"
				display="grid"
				gridTemplateColumns={{ base: '1fr', lg: '50% 50%' }}
				gridTemplateRows={{ base: '100% 100%', lg: '100%' }}
			>
				<Box
					id="details"
					order={{ base: 2, lg: 1 }}
					backgroundColor="primary.500"
					p={10}
					display="grid"
					gridTemplateRows="auto 1fr auto"
				>
					<Box>
						<Text
							alignItems="center"
							textAlign="left"
							display={{ base: 'flex', lg: 'none' }}
							mb={4}
							color="white"
							as="a"
							href="#booking-form"
							fontWeight={600}
							ml={-1}
							gap={1}
						>
							<FiChevronLeft size={18} />
							{t('Back')}
						</Text>

						{query.data.imageUrl ? <Avatar mixBlendMode="multiply" mb={4} size="md" src={query.data.imageUrl} /> : null}

						<Heading mb={6} fontSize={{ base: '2xl', lg: '5xl' }} color="white">
							{query.data.eventTypeTags?.ttBFHeaderText ? query.data.eventTypeTags?.ttBFHeaderText : query.data.name}
						</Heading>
					</Box>

					<Text whiteSpace="pre-wrap" fontSize={{ base: 'md', lg: 'xl' }} fontWeight={500} color="white">
						{query.data.eventTypeTags?.ttBFContent ? query.data.eventTypeTags?.ttBFContent : query.data.description}
					</Text>

					{appConfig.BookingFormPage?.hideBranding ? null : <TimeTimeBanner color="white" fontWeight={600} />}
				</Box>

				{hasAvailability ? (
					<BookingForm
						locale={locale}
						start={start.toJSDate()}
						end={end.toJSDate()}
						eventTypeTimezone={query.data.eventTypeTimeZone}
						onBackClick={onBackClick}
						onBookingConfirmed={handleBookingCreated}
						questions={questions}
						timeZone={timeZone}
						privacyPolicyUrl={privacyPolicyUrl}
						price={price}
					/>
				) : (
					<NoAvailabilityInfo onBackClick={onBackClick} />
				)}
			</Box>
		</ChakraProvider>
	);
}
