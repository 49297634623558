import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import { TTLANG } from './constants/queryParams';

// don't want to use this?
// have a look at the Quick start guide
// for passing in lng and translations on init

i18n
	// load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
	// learn more: https://github.com/i18next/i18next-http-backend
	.use(Backend)
	// detect user language
	// learn more: https://github.com/i18next/i18next-browser-languageDetector
	.use(LanguageDetector)
	// pass the i18n instance to react-i18next.
	.use(initReactI18next)
	// init i18next
	// for all options read: https://www.i18next.com/overview/configuration-options
	.init({
		lng: new URLSearchParams(window.location.search).get(TTLANG) ?? navigator.language ?? 'en',
		fallbackLng: 'en',
		load: 'languageOnly', // Load only required translations to save time
		debug: false,
		keySeparator: false, // we do not use keys in form messages.welcome
		interpolation: {
			escapeValue: false, // not needed for react as it escapes by default,
		},
	});

/**
 * Sync html lang attribute with the selected lng
 */
i18n.on('languageChanged', (lng) => {
	document.documentElement.setAttribute('lang', lng);
});

document.documentElement.setAttribute('lang', i18n.language);

export default i18n;
