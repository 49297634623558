import ImpersonatedUserContext from '@/context/ImpersonatedUserContext';
import IUser from '@/types/IUser';
import { PropsWithChildren, useState } from 'react';

export default function ImpersonatedUserProvider(props: PropsWithChildren<{ value?: IUser }>) {
	const [impersonatedUser, setImpersonatedUser] = useState<IUser | undefined>(props.value);

	return (
		<ImpersonatedUserContext.Provider value={{ impersonatedUser, setImpersonatedUser }}>
			{props.children}
		</ImpersonatedUserContext.Provider>
	);
}
