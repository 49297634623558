import { impersonate } from '@/api_client/client';
import AuthContext from '@/context/Auth.context';
import ImpersonatedUserContext from '@/context/ImpersonatedUserContext';
import authService from '@/services/auth.service';
import ILoginProvider from '@/types/ILoginProvider';
import IUser from '@/types/IUser';
import { useQueryClient } from '@tanstack/react-query';
import React from 'react';

export default function useAuth() {
	const authContext = React.useContext(AuthContext);
	const impersonatedUserContext = React.useContext(ImpersonatedUserContext);

	const queryClient = useQueryClient();

	if (!impersonatedUserContext) {
		throw Error('useAuth needs to be used inside an ImpersonatedUserProvider');
	}

	if (!authContext) {
		throw Error('useAuth needs to be used inside a provider');
	}

	function getCurrentUser(): IUser {
		if (impersonatedUserContext?.impersonatedUser) {
			return impersonatedUserContext.impersonatedUser;
		}
		return authContext?.user!;
	}

	function _impersonate(user?: IUser) {
		impersonate(user);
		impersonatedUserContext?.setImpersonatedUser(user);
		queryClient.removeQueries();
	}

	return {
		impersonatedUser: impersonatedUserContext.impersonatedUser,
		isAuthenticated: authContext.user !== null,
		isImpersonating: !!impersonatedUserContext.impersonatedUser,
		status: authContext.status,
		getCurrentUser,
		impersonate: _impersonate,
		login(provider: ILoginProvider) {
			return authService.login(provider).finally(() => {});
		},
		logout() {
			return authService.logout().finally(() => {
				queryClient.removeQueries();
			});
		},
	};
}
