import AppLoader from '@/components/AppLoader';
import { ILocale } from '@/constants/locales';
import i18n from '@/i18n';
import { useGetPublicEventTypeInfo } from '@/queries/eventTypes.queries';
import { useParams } from 'react-router-dom';
import BP1 from './themes/bp1/BP1';
import BP2 from './themes/bp2/BP2';

export default function BookingPage() {
	const { id } = useParams<{ id: string }>();
	const locale = i18n.language as ILocale;

	const query = useGetPublicEventTypeInfo({ id, locale });

	const theme = query.data?.eventTypeTags?.ttLayout;

	if (!query.data) {
		return <AppLoader />;
	}

	switch (theme) {
		case 'month':
			return <BP2 />;
		default:
			return <BP1 />;
	}
}
