import { formatDate } from '@/services/date-formatter.service';
import timezoneService from '@/services/timezone.service';
import { ICalendarEventInvitation } from '@/types/calendar-event-invitation/calendar-event-invitation';
import { Box, Grid, Text } from '@chakra-ui/react';
import { t } from 'i18next';

interface IProps {
	invitation: ICalendarEventInvitation;
}

export default function InvitationDate(props: IProps) {
	if (!props.invitation.event.interval) {
		return <Text textAlign="center">{t('No date info available')}</Text>;
	}

	switch (props.invitation.event.interval.type) {
		case 'ConcreteCalendarEventInvitationInterval': {
			const start = new Date(props.invitation.event.interval.interval.inclusiveStart.dateTime);
			const startTZ = props.invitation.event.interval.interval.inclusiveStart.timeZone ?? timezoneService.getTimeZone();

			const end = new Date(props.invitation.event.interval.interval.exclusiveEnd.dateTime);
			const endTz = props.invitation.event.interval.interval.exclusiveEnd.timeZone ?? timezoneService.getTimeZone();

			return (
				<Grid mx="auto" gridTemplateColumns="1fr auto 1fr" gap={2}>
					<Text fontSize="sm" letterSpacing="wider" textTransform="uppercase" color="gray.500">
						{formatDate({
							date: start,
							timeZone: startTZ,
							year: 'numeric',
							month: '2-digit',
							day: '2-digit',
							hour: 'numeric',
							minute: '2-digit',
							timeZoneName: 'short',
						})}
					</Text>
					<Text color="gray.500">~</Text>

					<Text fontSize="sm" letterSpacing="wider" textTransform="uppercase" color="gray.500">
						{formatDate({
							date: end,
							timeZone: endTz,
							year: 'numeric',
							month: '2-digit',
							day: '2-digit',
							hour: 'numeric',
							minute: '2-digit',
							timeZoneName: 'short',
						})}
					</Text>
				</Grid>
			);
		}

		case 'FuzzyCalendarEventInvitationInterval': {
			const start = new Date(props.invitation.event.interval.date);
			const startTZ = props.invitation.event.interval.timeZone ?? timezoneService.getTimeZone();
			return (
				<Box textAlign="center">
					<Text fontSize="sm" letterSpacing="wider" textTransform="uppercase" color="gray.500">
						{formatDate({
							date: start,
							timeZone: startTZ,
							year: 'numeric',
							month: '2-digit',
							day: '2-digit',
						})}
					</Text>

					<Text fontSize="sm" letterSpacing="wider" textTransform="uppercase" color="gray.500">
						{props.invitation.event.interval.fuzzyTime}
					</Text>
				</Box>
			);
		}

		case 'StartDateCalendarEventInvitationInterval': {
			return (
				<Text textAlign="center" fontSize="sm" letterSpacing="wider" textTransform="uppercase" color="gray.500">
					{props.invitation.event.interval.date} - {props.invitation.event.interval.timeZone}
				</Text>
			);
		}
	}
}
