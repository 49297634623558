import { ILocale } from "@/constants/locales";
import { IMonetaryAmount } from "@/types/common/imonetary-amount";

interface IOptions extends Intl.NumberFormatOptions {
    locale: ILocale;
}
export function format(amount: IMonetaryAmount, options?: IOptions): string {
    const amountNumber = Number(amount.amount);

    return new Intl.NumberFormat(options?.locale ?? 'en', {
        style: 'currency',
        currency: amount.currency,
        currencyDisplay: 'narrowSymbol',
        compactDisplay: 'short',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        ...options,
    }).format(amountNumber);
}

export default { format }