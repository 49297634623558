import { useSearchParams } from '@/hooks/useSearchParams';
import { Avatar, Box, Flex, Heading, Text } from '@chakra-ui/react';

export interface IBookingPageHeaderProps {
	name: string;
	description: string;
	imageUrl?: string;
}

export default function BookingPageHeader(props: IBookingPageHeaderProps) {
	const searchParams = useSearchParams();
	const shouldHideInfo = searchParams.get('hideInfo');

	if (shouldHideInfo) {
		return null;
	}

	return (
		<Flex
			background="white"
			borderBottomWidth={4}
			borderBottomStyle={'solid'}
			borderBottomColor="primary.500"
			py="4"
			px={{ base: '0', md: 6 }}
			alignItems="center"
			textAlign={{ base: 'center', md: 'left' }}
			flexDir={{ base: 'column', md: 'row' }}
		>
			{props.imageUrl ? (
				<Avatar
					name="Avatar"
					mr={{ base: 0, md: '4' }}
					size={{ base: 'lg', md: 'xl' }}
					src={props.imageUrl}
					loading="lazy"
				/>
			) : null}
			<Box>
				<Heading as="h1" size="lg">
					{props.name}
				</Heading>
				<Text as="h2" fontSize="md">
					{props.description}
				</Text>
			</Box>
		</Flex>
	);
}
