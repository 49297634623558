
interface IFormatDateArgs extends Intl.DateTimeFormatOptions {
	date: Date;
	locale?: string;
}

export function formatDate(args: IFormatDateArgs) {
	const locale = args.locale ?? navigator.language;
	const dtf = new Intl.DateTimeFormat(locale, args);
	return dtf.format(args.date);
}
