import { CLOUDINARY_CONFIG } from '@/constants/constants';
import loadScript from '@/utils/loadScript';

interface IUploadImageResult {
	event: 'success' | 'close' | string;
	info: Record<string, string>;
}

export async function openUploadWidget(): Promise<IUploadImageResult> {
	await loadScript('https://upload-widget.cloudinary.com/global/all.js');

	return new Promise((resolve, reject) => {
		if (!window.cloudinary) {
			reject('Cloudinary is not defined');
		}

		cloudinary.openUploadWidget(
			CLOUDINARY_CONFIG,
			(error: unknown, result: { event: string; info: Record<string, string> }) => {
				if (error) {
					return reject(error);
				}
				switch (result.event) {
					case 'success':
					case 'close':
						return resolve(result);
				}
			}
		);
	});
}

export default { openUploadWidget };
