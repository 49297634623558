import { ICalendarEventInvitation } from '@/types/calendar-event-invitation/calendar-event-invitation';
import { Box, Heading, Text } from '@chakra-ui/react';
import InvitationActions from './components/invitation-actions/invitation-actions.view';
import InvitationDate from './components/invitation-date.view';

interface IProps {
	invitation: ICalendarEventInvitation;
}

export default function InvitationComponent(props: IProps) {
	return (
		<Box
			bg="white"
			borderColor="gray.200"
			borderRadius={{ base: 0, sm: 'md' }}
			borderWidth={1}
			display="grid"
			gridTemplateRows="auto auto auto 1fr"
			h={{ base: 'full', sm: 'auto' }}
			maxW="lg"
			mt={{ base: 0, sm: 8 }}
			mx="auto"
			p={{ base: 4, sm: 8 }}
			gap={4}
		>
			{props.invitation.summary ? (
				<Heading textAlign="center" as="h1" size="lg" fontWeight="bold" letterSpacing="tighter">
					{props.invitation.summary}
				</Heading>
			) : null}

			<InvitationDate invitation={props.invitation} />

			<Heading as="h2" size="md" fontWeight="bold" textAlign="center">
				{props.invitation.subtitle}
			</Heading>

			<Text fontSize="sm" lineHeight="loose" color="gray.500">
				{props.invitation.description}
			</Text>

			<InvitationActions invitation={props.invitation} />
		</Box>
	);
}
