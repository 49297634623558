import ISlot from '@/types/Slot';

export function organizeSlots(slots: Array<ISlot>, timeZone: string): ISlot[][] {
	const grid: Array<Array<ISlot>> = [];
	const { min, max } = _getMinMaxAvailableTimes(slots);
	let lastDay = '';

	for (let i = 0; i < slots.length; i++) {
		const slot = slots[i];

		if (lastDay !== _getDay(slot, timeZone)) {
			_isInRange(slot, min, max) ? grid.push([slot]) : grid.push([]);
			lastDay = _getDay(slot, timeZone);
			continue;
		}

		_pushInRange(slot, grid[grid.length - 1], min, max);
	}

	// Remove empty arrays (this might happen when slot if out of range!)
	return grid.filter((group) => group.length !== 0);
}

function _pushInRange(slot: ISlot, slots: Array<ISlot>, min: number, max: number) {
	if (_isInRange(slot, min, max)) {
		slots.push(slot);
	}
}

function _isInRange(slot: ISlot, min: number, max: number) {
	return slot.start.hour <= max && slot.start.hour >= min;
}

function _getDay(slot: ISlot, timeZone: string): string {
	return slot.start.toISODate() as string;
}

function _getMinMaxAvailableTimes(slots: Array<ISlot>): { min: number; max: number } {
	let min = 24;
	let max = 0;

	slots.forEach((slot) => {
		if (slot.score > 0 && slot.start.hour < min) {
			min = slot.start.hour;
		}
		if (slot.score > 0 && slot.start.hour > max) {
			max = slot.start.hour;
		}
	});

	return { min, max };
}

export default { organiseSlots: organizeSlots };
