import { ROUTE_DASHBOARD } from '@/constants/routes';
import { useSearchParams } from '@/hooks/useSearchParams';
import { useLoginWithTokenQuery } from '@/queries/auth.queries';
import { useHistory } from 'react-router-dom';

export function useLoginWithTokenPageController() {
	const history = useHistory();
	const params = useSearchParams();
	const token = params.get('token');
	const redirect = params.get('redirect');

	const query = useLoginWithTokenQuery({ token });

	if (query.isSuccess) {
		redirect ? history.push(redirect) : history.push(ROUTE_DASHBOARD);
	}

	return {
		query,
	};
}
