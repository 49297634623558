import ITimeZone from '@/types/ITimeZone';
import { Select, SelectProps } from '@chakra-ui/react';
import React from 'react';
import { TbWorld } from 'react-icons/tb';

interface ITimeZoneSelectProps extends SelectProps {
	onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
	value: ITimeZone;
	isDisabled?: boolean;
}

export default function TimeZoneSelect(props: ITimeZoneSelectProps) {
	return (
		<Select
			mx="auto"
			variant="ghost"
			size="sm"
			isDisabled={props.isDisabled}
			cursor={props.isDisabled ? 'progress' : 'pointer'}
			icon={<TbWorld />}
			{...props}
		>
			<optgroup label="Canada">
				<option value="Canada/Newfoundland">Canada/Newfoundland</option>
				<option value="Canada/Atlantic">Canada/Atlantic</option>
				<option value="Canada/Eastern">Canada/Eastern</option>
				<option value="Canada/Central">Canada/Central</option>
				<option value="Canada/Saskatchewan">Canada/Saskatchewan</option>
				<option value="Canada/Mountain">Canada/Mountain</option>
				<option value="Canada/Yukon">Canada/Yukon</option>
				<option value="Canada/Pacific">Canada/Pacific</option>
			</optgroup>

			<optgroup label="USA">
				<option value="US/Eastern">USA/Eastern</option>
				<option value="US/Central">USA/Central</option>
				<option value="US/Mountain">USA/Mountain</option>
				<option value="US/Pacific">USA/Pacific</option>
				<option value="US/Arizona">USA/Arizona</option>
				<option value="US/Alaska">USA/Alaska</option>
				<option value="US/Hawaii">USA/Hawaii</option>
			</optgroup>

			<optgroup label="Americas">
				<option value="America/Adak">Americas/Adak</option>
				<option value="America/Anchorage">Americas/Anchorage</option>
				<option value="America/Anguilla">Americas/Anguilla</option>
				<option value="America/Antigua">Americas/Antigua</option>
				<option value="America/Araguaina">Americas/Araguaina</option>
				<option value="America/Argentina/Buenos_Aires">Americas/Argentina/Buenos Aires</option>
				<option value="America/Argentina/Catamarca">Americas/Argentina/Catamarca</option>
				<option value="America/Argentina/ComodRivadavia">Americas/Argentina/ComodRivadavia</option>
				<option value="America/Argentina/Cordoba">Americas/Argentina/Cordoba</option>
				<option value="America/Argentina/Jujuy">Americas/Argentina/Jujuy</option>
				<option value="America/Argentina/La_Rioja">Americas/Argentina/La Rioja</option>
				<option value="America/Argentina/Mendoza">Americas/Argentina/Mendoza</option>
				<option value="America/Argentina/Rio_Gallegos">Americas/Argentina/Rio Gallegos</option>
				<option value="America/Argentina/Salta">Americas/Argentina/Salta</option>
				<option value="America/Argentina/San_Juan">Americas/Argentina/San Juan</option>
				<option value="America/Argentina/San_Luis">Americas/Argentina/San Luis</option>
				<option value="America/Argentina/Tucuman">Americas/Argentina/Tucuman</option>
				<option value="America/Argentina/Ushuaia">Americas/Argentina/Ushuaia</option>
				<option value="America/Aruba">Americas/Aruba</option>
				<option value="America/Asuncion">Americas/Asuncion</option>
				<option value="America/Atikokan">Americas/Atikokan</option>
				<option value="America/Atka">Americas/Atka</option>
				<option value="America/Bahia">Americas/Bahia</option>
				<option value="America/Bahia_Banderas">Americas/Bahia Banderas</option>
				<option value="America/Barbados">Americas/Barbados</option>
				<option value="America/Belem">Americas/Belem</option>
				<option value="America/Belize">Americas/Belize</option>
				<option value="America/Blanc-Sablon">Americas/Blanc-Sablon</option>
				<option value="America/Boa_Vista">Americas/Boa Vista</option>
				<option value="America/Bogota">Americas/Bogota</option>
				<option value="America/Boise">Americas/Boise</option>
				<option value="America/Buenos_Aires">Americas/Buenos Aires</option>
				<option value="America/Cambridge_Bay">Americas/Cambridge Bay</option>
				<option value="America/Campo_Grande">Americas/Campo Grande</option>
				<option value="America/Cancun">Americas/Cancun</option>
				<option value="America/Caracas">Americas/Caracas</option>
				<option value="America/Catamarca">Americas/Catamarca</option>
				<option value="America/Cayenne">Americas/Cayenne</option>
				<option value="America/Cayman">Americas/Cayman</option>
				<option value="America/Chicago">Americas/Chicago</option>
				<option value="America/Chihuahua">Americas/Chihuahua</option>
				<option value="America/Coral_Harbour">Americas/Coral Harbour</option>
				<option value="America/Cordoba">Americas/Cordoba</option>
				<option value="America/Costa_Rica">Americas/Costa Rica</option>
				<option value="America/Creston">Americas/Creston</option>
				<option value="America/Cuiaba">Americas/Cuiaba</option>
				<option value="America/Curacao">Americas/Curacao</option>
				<option value="America/Dawson">Americas/Dawson</option>
				<option value="America/Dawson_Creek">Americas/Dawson Creek</option>
				<option value="America/Denver">Americas/Denver</option>
				<option value="America/Detroit">Americas/Detroit</option>
				<option value="America/Dominica">Americas/Dominica</option>
				<option value="America/Edmonton">Americas/Edmonton</option>
				<option value="America/Eirunepe">Americas/Eirunepe</option>
				<option value="America/El_Salvador">Americas/El Salvador</option>
				<option value="America/Ensenada">Americas/Ensenada</option>
				<option value="America/Fort_Nelson">Americas/Fort Nelson</option>
				<option value="America/Fort_Wayne">Americas/Fort Wayne</option>
				<option value="America/Fortaleza">Americas/Fortaleza</option>
				<option value="America/Glace_Bay">Americas/Glace Bay</option>
				<option value="America/Godthab">Americas/Godthab</option>
				<option value="America/Goose_Bay">Americas/Goose Bay</option>
				<option value="America/Grand_Turk">Americas/Grand Turk</option>
				<option value="America/Grenada">Americas/Grenada</option>
				<option value="America/Guadeloupe">Americas/Guadeloupe</option>
				<option value="America/Guatemala">Americas/Guatemala</option>
				<option value="America/Guayaquil">Americas/Guayaquil</option>
				<option value="America/Guyana">Americas/Guyana</option>
				<option value="America/Halifax">Americas/Halifax</option>
				<option value="America/Havana">Americas/Havana</option>
				<option value="America/Hermosillo">Americas/Hermosillo</option>
				<option value="America/Indiana/Indianapolis">Americas/Indiana/Indianapolis</option>
				<option value="America/Indiana/Knox">Americas/Indiana/Knox</option>
				<option value="America/Indiana/Marengo">Americas/Indiana/Marengo</option>
				<option value="America/Indiana/Petersburg">Americas/Indiana/Petersburg</option>
				<option value="America/Indiana/Tell_City">Americas/Indiana/Tell City</option>
				<option value="America/Indiana/Vevay">Americas/Indiana/Vevay</option>
				<option value="America/Indiana/Vincennes">Americas/Indiana/Vincennes</option>
				<option value="America/Indiana/Winamac">Americas/Indiana/Winamac</option>
				<option value="America/Indianapolis">Americas/Indianapolis</option>
				<option value="America/Inuvik">Americas/Inuvik</option>
				<option value="America/Iqaluit">Americas/Iqaluit</option>
				<option value="America/Jamaica">Americas/Jamaica</option>
				<option value="America/Jujuy">Americas/Jujuy</option>
				<option value="America/Juneau">Americas/Juneau</option>
				<option value="America/Kentucky/Louisville">Americas/Kentucky/Louisville</option>
				<option value="America/Kentucky/Monticello">Americas/Kentucky/Monticello</option>
				<option value="America/Knox_IN">Americas/Knox IN</option>
				<option value="America/Kralendijk">Americas/Kralendijk</option>
				<option value="America/La_Paz">Americas/La Paz</option>
				<option value="America/Lima">Americas/Lima</option>
				<option value="America/Los_Angeles">Americas/Los Angeles</option>
				<option value="America/Louisville">Americas/Louisville</option>
				<option value="America/Lower_Princes">Americas/Lower Princes</option>
				<option value="America/Maceio">Americas/Maceio</option>
				<option value="America/Managua">Americas/Managua</option>
				<option value="America/Manaus">Americas/Manaus</option>
				<option value="America/Marigot">Americas/Marigot</option>
				<option value="America/Martinique">Americas/Martinique</option>
				<option value="America/Matamoros">Americas/Matamoros</option>
				<option value="America/Mazatlan">Americas/Mazatlan</option>
				<option value="America/Mendoza">Americas/Mendoza</option>
				<option value="America/Menominee">Americas/Menominee</option>
				<option value="America/Merida">Americas/Merida</option>
				<option value="America/Metlakatla">Americas/Metlakatla</option>
				<option value="America/Mexico_City">Americas/Mexico City</option>
				<option value="America/Miquelon">Americas/Miquelon</option>
				<option value="America/Moncton">Americas/Moncton</option>
				<option value="America/Monterrey">Americas/Monterrey</option>
				<option value="America/Montevideo">Americas/Montevideo</option>
				<option value="America/Montreal">Americas/Montreal</option>
				<option value="America/Montserrat">Americas/Montserrat</option>
				<option value="America/Nassau">Americas/Nassau</option>
				<option value="America/New_York">Americas/New York</option>
				<option value="America/Nipigon">Americas/Nipigon</option>
				<option value="America/Nome">Americas/Nome</option>
				<option value="America/Noronha">Americas/Noronha</option>
				<option value="America/North_Dakota/Beulah">Americas/North Dakota/Beulah</option>
				<option value="America/North_Dakota/Center">Americas/North Dakota/Center</option>
				<option value="America/North_Dakota/New_Salem">Americas/North Dakota/New Salem</option>
				<option value="America/Nuuk">Americas/Nuuk</option>
				<option value="America/Ojinaga">Americas/Ojinaga</option>
				<option value="America/Panama">Americas/Panama</option>
				<option value="America/Pangnirtung">Americas/Pangnirtung</option>
				<option value="America/Paramaribo">Americas/Paramaribo</option>
				<option value="America/Phoenix">Americas/Phoenix</option>
				<option value="America/Port_of_Spain">Americas/Port of Spain</option>
				<option value="America/Port-au-Prince">Americas/Port-au-Prince</option>
				<option value="America/Porto_Acre">Americas/Porto Acre</option>
				<option value="America/Porto_Velho">Americas/Porto Velho</option>
				<option value="America/Puerto_Rico">Americas/Puerto Rico</option>
				<option value="America/Punta_Arenas">Americas/Punta Arenas</option>
				<option value="America/Rainy_River">Americas/Rainy River</option>
				<option value="America/Rankin_Inlet">Americas/Rankin Inlet</option>
				<option value="America/Recife">Americas/Recife</option>
				<option value="America/Regina">Americas/Regina</option>
				<option value="America/Resolute">Americas/Resolute</option>
				<option value="America/Rio_Branco">Americas/Rio Branco</option>
				<option value="America/Rosario">Americas/Rosario</option>
				<option value="America/Santa_Isabel">Americas/Santa Isabel</option>
				<option value="America/Santarem">Americas/Santarem</option>
				<option value="America/Santiago">Americas/Santiago</option>
				<option value="America/Santo_Domingo">Americas/Santo Domingo</option>
				<option value="America/Sao_Paulo">Americas/Sao Paulo</option>
				<option value="America/Scoresbysund">Americas/Scoresbysund</option>
				<option value="America/Shiprock">Americas/Shiprock</option>
				<option value="America/Sitka">Americas/Sitka</option>
				<option value="America/St_Barthelemy">Americas/St Barthelemy</option>
				<option value="America/St_Johns">Americas/St Johns</option>
				<option value="America/St_Kitts">Americas/St Kitts</option>
				<option value="America/St_Lucia">Americas/St Lucia</option>
				<option value="America/St_Thomas">Americas/St Thomas</option>
				<option value="America/St_Vincent">Americas/St Vincent</option>
				<option value="America/Swift_Current">Americas/Swift Current</option>
				<option value="America/Tegucigalpa">Americas/Tegucigalpa</option>
				<option value="America/Thule">Americas/Thule</option>
				<option value="America/Thunder_Bay">Americas/Thunder Bay</option>
				<option value="America/Tijuana">Americas/Tijuana</option>
				<option value="America/Toronto">Americas/Toronto</option>
				<option value="America/Tortola">Americas/Tortola</option>
				<option value="America/Vancouver">Americas/Vancouver</option>
				<option value="America/Virgin">Americas/Virgin</option>
				<option value="America/Whitehorse">Americas/Whitehorse</option>
				<option value="America/Winnipeg">Americas/Winnipeg</option>
				<option value="America/Yakutat">Americas/Yakutat</option>
				<option value="America/Yellowknife">Americas/Yellowknife</option>
			</optgroup>

			<optgroup label="Atlantic">
				<option value="Atlantic/Azores">Atlantic/Azores</option>
				<option value="Atlantic/Bermuda">Atlantic/Bermuda</option>
				<option value="Atlantic/Canary">Atlantic/Canary</option>
				<option value="Atlantic/Cape_Verde">Atlantic/Cape Verde</option>
				<option value="Atlantic/Faeroe">Atlantic/Faeroe</option>
				<option value="Atlantic/Faroe">Atlantic/Faroe</option>
				<option value="Atlantic/Jan_Mayen">Atlantic/Jan Mayen</option>
				<option value="Atlantic/Madeira">Atlantic/Madeira</option>
				<option value="Atlantic/Reykjavik">Atlantic/Reykjavik</option>
				<option value="Atlantic/South_Georgia">Atlantic/South Georgia</option>
				<option value="Atlantic/St_Helena">Atlantic/St Helena</option>
				<option value="Atlantic/Stanley">Atlantic/Stanley</option>
			</optgroup>

			<optgroup label="Europe">
				<option value="Europe/Amsterdam">Europe/Amsterdam</option>
				<option value="Europe/Andorra">Europe/Andorra</option>
				<option value="Europe/Astrakhan">Europe/Astrakhan</option>
				<option value="Europe/Athens">Europe/Athens</option>
				<option value="Europe/Belfast">Europe/Belfast</option>
				<option value="Europe/Belgrade">Europe/Belgrade</option>
				<option value="Europe/Berlin">Europe/Berlin</option>
				<option value="Europe/Bratislava">Europe/Bratislava</option>
				<option value="Europe/Brussels">Europe/Brussels</option>
				<option value="Europe/Bucharest">Europe/Bucharest</option>
				<option value="Europe/Budapest">Europe/Budapest</option>
				<option value="Europe/Busingen">Europe/Busingen</option>
				<option value="Europe/Chisinau">Europe/Chisinau</option>
				<option value="Europe/Copenhagen">Europe/Copenhagen</option>
				<option value="Europe/Dublin">Europe/Dublin</option>
				<option value="Europe/Gibraltar">Europe/Gibraltar</option>
				<option value="Europe/Guernsey">Europe/Guernsey</option>
				<option value="Europe/Helsinki">Europe/Helsinki</option>
				<option value="Europe/Isle_of_Man">Europe/Isle of Man</option>
				<option value="Europe/Istanbul">Europe/Istanbul</option>
				<option value="Europe/Jersey">Europe/Jersey</option>
				<option value="Europe/Kaliningrad">Europe/Kaliningrad</option>
				<option value="Europe/Kiev">Europe/Kiev</option>
				<option value="Europe/Kirov">Europe/Kirov</option>
				<option value="Europe/Lisbon">Europe/Lisbon</option>
				<option value="Europe/Ljubljana">Europe/Ljubljana</option>
				<option value="Europe/London">Europe/London</option>
				<option value="Europe/Luxembourg">Europe/Luxembourg</option>
				<option value="Europe/Madrid">Europe/Madrid</option>
				<option value="Europe/Malta">Europe/Malta</option>
				<option value="Europe/Mariehamn">Europe/Mariehamn</option>
				<option value="Europe/Minsk">Europe/Minsk</option>
				<option value="Europe/Monaco">Europe/Monaco</option>
				<option value="Europe/Moscow">Europe/Moscow</option>
				<option value="Europe/Nicosia">Europe/Nicosia</option>
				<option value="Europe/Oslo">Europe/Oslo</option>
				<option value="Europe/Paris">Europe/Paris</option>
				<option value="Europe/Podgorica">Europe/Podgorica</option>
				<option value="Europe/Prague">Europe/Prague</option>
				<option value="Europe/Riga">Europe/Riga</option>
				<option value="Europe/Rome">Europe/Rome</option>
				<option value="Europe/Samara">Europe/Samara</option>
				<option value="Europe/San_Marino">Europe/San Marino</option>
				<option value="Europe/Sarajevo">Europe/Sarajevo</option>
				<option value="Europe/Saratov">Europe/Saratov</option>
				<option value="Europe/Simferopol">Europe/Simferopol</option>
				<option value="Europe/Skopje">Europe/Skopje</option>
				<option value="Europe/Sofia">Europe/Sofia</option>
				<option value="Europe/Stockholm">Europe/Stockholm</option>
				<option value="Europe/Tallinn">Europe/Tallinn</option>
				<option value="Europe/Tirane">Europe/Tirane</option>
				<option value="Europe/Tiraspol">Europe/Tiraspol</option>
				<option value="Europe/Ulyanovsk">Europe/Ulyanovsk</option>
				<option value="Europe/Uzhgorod">Europe/Uzhgorod</option>
				<option value="Europe/Vaduz">Europe/Vaduz</option>
				<option value="Europe/Vatican">Europe/Vatican</option>
				<option value="Europe/Vienna">Europe/Vienna</option>
				<option value="Europe/Vilnius">Europe/Vilnius</option>
				<option value="Europe/Volgograd">Europe/Volgograd</option>
				<option value="Europe/Warsaw">Europe/Warsaw</option>
				<option value="Europe/Zagreb">Europe/Zagreb</option>
				<option value="Europe/Zaporozhye">Europe/Zaporozhye</option>
				<option value="Europe/Zurich">Europe/Zurich</option>
			</optgroup>

			<optgroup label="Africa">
				<option value="Africa/Abidjan">Africa/Abidjan</option>
				<option value="Africa/Accra">Africa/Accra</option>
				<option value="Africa/Addis_Ababa">Africa/Addis Ababa</option>
				<option value="Africa/Algiers">Africa/Algiers</option>
				<option value="Africa/Asmara">Africa/Asmara</option>
				<option value="Africa/Asmera">Africa/Asmera</option>
				<option value="Africa/Bamako">Africa/Bamako</option>
				<option value="Africa/Bangui">Africa/Bangui</option>
				<option value="Africa/Banjul">Africa/Banjul</option>
				<option value="Africa/Bissau">Africa/Bissau</option>
				<option value="Africa/Blantyre">Africa/Blantyre</option>
				<option value="Africa/Brazzaville">Africa/Brazzaville</option>
				<option value="Africa/Bujumbura">Africa/Bujumbura</option>
				<option value="Africa/Cairo">Africa/Cairo</option>
				<option value="Africa/Casablanca">Africa/Casablanca</option>
				<option value="Africa/Ceuta">Africa/Ceuta</option>
				<option value="Africa/Conakry">Africa/Conakry</option>
				<option value="Africa/Dakar">Africa/Dakar</option>
				<option value="Africa/Dar_es_Salaam">Africa/Dar es Salaam</option>
				<option value="Africa/Djibouti">Africa/Djibouti</option>
				<option value="Africa/Douala">Africa/Douala</option>
				<option value="Africa/El_Aaiun">Africa/El Aaiun</option>
				<option value="Africa/Freetown">Africa/Freetown</option>
				<option value="Africa/Gaborone">Africa/Gaborone</option>
				<option value="Africa/Harare">Africa/Harare</option>
				<option value="Africa/Johannesburg">Africa/Johannesburg</option>
				<option value="Africa/Juba">Africa/Juba</option>
				<option value="Africa/Kampala">Africa/Kampala</option>
				<option value="Africa/Khartoum">Africa/Khartoum</option>
				<option value="Africa/Kigali">Africa/Kigali</option>
				<option value="Africa/Kinshasa">Africa/Kinshasa</option>
				<option value="Africa/Lagos">Africa/Lagos</option>
				<option value="Africa/Libreville">Africa/Libreville</option>
				<option value="Africa/Lome">Africa/Lome</option>
				<option value="Africa/Luanda">Africa/Luanda</option>
				<option value="Africa/Lubumbashi">Africa/Lubumbashi</option>
				<option value="Africa/Lusaka">Africa/Lusaka</option>
				<option value="Africa/Malabo">Africa/Malabo</option>
				<option value="Africa/Maputo">Africa/Maputo</option>
				<option value="Africa/Maseru">Africa/Maseru</option>
				<option value="Africa/Mbabane">Africa/Mbabane</option>
				<option value="Africa/Mogadishu">Africa/Mogadishu</option>
				<option value="Africa/Monrovia">Africa/Monrovia</option>
				<option value="Africa/Nairobi">Africa/Nairobi</option>
				<option value="Africa/Ndjamena">Africa/Ndjamena</option>
				<option value="Africa/Niamey">Africa/Niamey</option>
				<option value="Africa/Nouakchott">Africa/Nouakchott</option>
				<option value="Africa/Ouagadougou">Africa/Ouagadougou</option>
				<option value="Africa/Porto-Novo">Africa/Porto-Novo</option>
				<option value="Africa/Sao_Tome">Africa/Sao Tome</option>
				<option value="Africa/Timbuktu">Africa/Timbuktu</option>
				<option value="Africa/Tripoli">Africa/Tripoli</option>
				<option value="Africa/Tunis">Africa/Tunis</option>
				<option value="Africa/Windhoek">Africa/Windhoek</option>
			</optgroup>

			<optgroup label="Asia">
				<option value="Asia/Aden">Asia/Aden</option>
				<option value="Asia/Almaty">Asia/Almaty</option>
				<option value="Asia/Amman">Asia/Amman</option>
				<option value="Asia/Anadyr">Asia/Anadyr</option>
				<option value="Asia/Aqtau">Asia/Aqtau</option>
				<option value="Asia/Aqtobe">Asia/Aqtobe</option>
				<option value="Asia/Ashgabat">Asia/Ashgabat</option>
				<option value="Asia/Ashkhabad">Asia/Ashkhabad</option>
				<option value="Asia/Atyrau">Asia/Atyrau</option>
				<option value="Asia/Baghdad">Asia/Baghdad</option>
				<option value="Asia/Bahrain">Asia/Bahrain</option>
				<option value="Asia/Baku">Asia/Baku</option>
				<option value="Asia/Bangkok">Asia/Bangkok</option>
				<option value="Asia/Barnaul">Asia/Barnaul</option>
				<option value="Asia/Beirut">Asia/Beirut</option>
				<option value="Asia/Bishkek">Asia/Bishkek</option>
				<option value="Asia/Brunei">Asia/Brunei</option>
				<option value="Asia/Calcutta">Asia/Calcutta</option>
				<option value="Asia/Chita">Asia/Chita</option>
				<option value="Asia/Choibalsan">Asia/Choibalsan</option>
				<option value="Asia/Chongqing">Asia/Chongqing</option>
				<option value="Asia/Chungking">Asia/Chungking</option>
				<option value="Asia/Colombo">Asia/Colombo</option>
				<option value="Asia/Dacca">Asia/Dacca</option>
				<option value="Asia/Damascus">Asia/Damascus</option>
				<option value="Asia/Dhaka">Asia/Dhaka</option>
				<option value="Asia/Dili">Asia/Dili</option>
				<option value="Asia/Dubai">Asia/Dubai</option>
				<option value="Asia/Dushanbe">Asia/Dushanbe</option>
				<option value="Asia/Famagusta">Asia/Famagusta</option>
				<option value="Asia/Gaza">Asia/Gaza</option>
				<option value="Asia/Harbin">Asia/Harbin</option>
				<option value="Asia/Hebron">Asia/Hebron</option>
				<option value="Asia/Ho_Chi_Minh">Asia/Ho Chi Minh</option>
				<option value="Asia/Hong_Kong">Asia/Hong Kong</option>
				<option value="Asia/Hovd">Asia/Hovd</option>
				<option value="Asia/Irkutsk">Asia/Irkutsk</option>
				<option value="Asia/Istanbul">Asia/Istanbul</option>
				<option value="Asia/Jakarta">Asia/Jakarta</option>
				<option value="Asia/Jayapura">Asia/Jayapura</option>
				<option value="Asia/Jerusalem">Asia/Jerusalem</option>
				<option value="Asia/Kabul">Asia/Kabul</option>
				<option value="Asia/Kamchatka">Asia/Kamchatka</option>
				<option value="Asia/Karachi">Asia/Karachi</option>
				<option value="Asia/Kashgar">Asia/Kashgar</option>
				<option value="Asia/Kathmandu">Asia/Kathmandu</option>
				<option value="Asia/Katmandu">Asia/Katmandu</option>
				<option value="Asia/Khandyga">Asia/Khandyga</option>
				<option value="Asia/Kolkata">Asia/Kolkata</option>
				<option value="Asia/Krasnoyarsk">Asia/Krasnoyarsk</option>
				<option value="Asia/Kuala_Lumpur">Asia/Kuala Lumpur</option>
				<option value="Asia/Kuching">Asia/Kuching</option>
				<option value="Asia/Kuwait">Asia/Kuwait</option>
				<option value="Asia/Macao">Asia/Macao</option>
				<option value="Asia/Macau">Asia/Macau</option>
				<option value="Asia/Magadan">Asia/Magadan</option>
				<option value="Asia/Makassar">Asia/Makassar</option>
				<option value="Asia/Manila">Asia/Manila</option>
				<option value="Asia/Muscat">Asia/Muscat</option>
				<option value="Asia/Nicosia">Asia/Nicosia</option>
				<option value="Asia/Novokuznetsk">Asia/Novokuznetsk</option>
				<option value="Asia/Novosibirsk">Asia/Novosibirsk</option>
				<option value="Asia/Omsk">Asia/Omsk</option>
				<option value="Asia/Oral">Asia/Oral</option>
				<option value="Asia/Phnom_Penh">Asia/Phnom Penh</option>
				<option value="Asia/Pontianak">Asia/Pontianak</option>
				<option value="Asia/Pyongyang">Asia/Pyongyang</option>
				<option value="Asia/Qatar">Asia/Qatar</option>
				<option value="Asia/Qostanay">Asia/Qostanay</option>
				<option value="Asia/Qyzylorda">Asia/Qyzylorda</option>
				<option value="Asia/Rangoon">Asia/Rangoon</option>
				<option value="Asia/Riyadh">Asia/Riyadh</option>
				<option value="Asia/Saigon">Asia/Saigon</option>
				<option value="Asia/Sakhalin">Asia/Sakhalin</option>
				<option value="Asia/Samarkand">Asia/Samarkand</option>
				<option value="Asia/Seoul">Asia/Seoul</option>
				<option value="Asia/Shanghai">Asia/Shanghai</option>
				<option value="Asia/Singapore">Asia/Singapore</option>
				<option value="Asia/Srednekolymsk">Asia/Srednekolymsk</option>
				<option value="Asia/Taipei">Asia/Taipei</option>
				<option value="Asia/Tashkent">Asia/Tashkent</option>
				<option value="Asia/Tbilisi">Asia/Tbilisi</option>
				<option value="Asia/Tehran">Asia/Tehran</option>
				<option value="Asia/Tel_Aviv">Asia/Tel Aviv</option>
				<option value="Asia/Thimbu">Asia/Thimbu</option>
				<option value="Asia/Thimphu">Asia/Thimphu</option>
				<option value="Asia/Tokyo">Asia/Tokyo</option>
				<option value="Asia/Tomsk">Asia/Tomsk</option>
				<option value="Asia/Ujung_Pandang">Asia/Ujung Pandang</option>
				<option value="Asia/Ulaanbaatar">Asia/Ulaanbaatar</option>
				<option value="Asia/Ulan_Bator">Asia/Ulan Bator</option>
				<option value="Asia/Urumqi">Asia/Urumqi</option>
				<option value="Asia/Ust-Nera">Asia/Ust-Nera</option>
				<option value="Asia/Vientiane">Asia/Vientiane</option>
				<option value="Asia/Vladivostok">Asia/Vladivostok</option>
				<option value="Asia/Yakutsk">Asia/Yakutsk</option>
				<option value="Asia/Yangon">Asia/Yangon</option>
				<option value="Asia/Yekaterinburg">Asia/Yekaterinburg</option>
				<option value="Asia/Yerevan">Asia/Yerevan</option>
			</optgroup>

			<optgroup label="Australia">
				<option value="Australia/ACT">Australia/ACT</option>
				<option value="Australia/Adelaide">Australia/Adelaide</option>
				<option value="Australia/Brisbane">Australia/Brisbane</option>
				<option value="Australia/Broken_Hill">Australia/Broken Hill</option>
				<option value="Australia/Canberra">Australia/Canberra</option>
				<option value="Australia/Currie">Australia/Currie</option>
				<option value="Australia/Darwin">Australia/Darwin</option>
				<option value="Australia/Eucla">Australia/Eucla</option>
				<option value="Australia/Hobart">Australia/Hobart</option>
				<option value="Australia/LHI">Australia/LHI</option>
				<option value="Australia/Lindeman">Australia/Lindeman</option>
				<option value="Australia/Lord_Howe">Australia/Lord Howe</option>
				<option value="Australia/Melbourne">Australia/Melbourne</option>
				<option value="Australia/NSW">Australia/NSW</option>
				<option value="Australia/North">Australia/North</option>
				<option value="Australia/Perth">Australia/Perth</option>
				<option value="Australia/Queensland">Australia/Queensland</option>
				<option value="Australia/South">Australia/South</option>
				<option value="Australia/Sydney">Australia/Sydney</option>
				<option value="Australia/Tasmania">Australia/Tasmania</option>
				<option value="Australia/Victoria">Australia/Victoria</option>
				<option value="Australia/West">Australia/West</option>
				<option value="Australia/Yancowinna">Australia/Yancowinna</option>
			</optgroup>

			<optgroup label="Pacific">
				<option value="Pacific/Apia">Pacific/Apia</option>
				<option value="Pacific/Auckland">Pacific/Auckland</option>
				<option value="Pacific/Bougainville">Pacific/Bougainville</option>
				<option value="Pacific/Chatham">Pacific/Chatham</option>
				<option value="Pacific/Chuuk">Pacific/Chuuk</option>
				<option value="Pacific/Easter">Pacific/Easter</option>
				<option value="Pacific/Efate">Pacific/Efate</option>
				<option value="Pacific/Enderbury">Pacific/Enderbury</option>
				<option value="Pacific/Fakaofo">Pacific/Fakaofo</option>
				<option value="Pacific/Fiji">Pacific/Fiji</option>
				<option value="Pacific/Funafuti">Pacific/Funafuti</option>
				<option value="Pacific/Galapagos">Pacific/Galapagos</option>
				<option value="Pacific/Gambier">Pacific/Gambier</option>
				<option value="Pacific/Guadalcanal">Pacific/Guadalcanal</option>
				<option value="Pacific/Guam">Pacific/Guam</option>
				<option value="Pacific/Honolulu">Pacific/Honolulu</option>
				<option value="Pacific/Johnston">Pacific/Johnston</option>
				<option value="Pacific/Kiritimati">Pacific/Kiritimati</option>
				<option value="Pacific/Kosrae">Pacific/Kosrae</option>
				<option value="Pacific/Kwajalein">Pacific/Kwajalein</option>
				<option value="Pacific/Majuro">Pacific/Majuro</option>
				<option value="Pacific/Marquesas">Pacific/Marquesas</option>
				<option value="Pacific/Midway">Pacific/Midway</option>
				<option value="Pacific/Nauru">Pacific/Nauru</option>
				<option value="Pacific/Niue">Pacific/Niue</option>
				<option value="Pacific/Norfolk">Pacific/Norfolk</option>
				<option value="Pacific/Noumea">Pacific/Noumea</option>
				<option value="Pacific/Pago_Pago">Pacific/Pago Pago</option>
				<option value="Pacific/Palau">Pacific/Palau</option>
				<option value="Pacific/Pitcairn">Pacific/Pitcairn</option>
				<option value="Pacific/Pohnpei">Pacific/Pohnpei</option>
				<option value="Pacific/Ponape">Pacific/Ponape</option>
				<option value="Pacific/Port_Moresby">Pacific/Port Moresby</option>
				<option value="Pacific/Rarotonga">Pacific/Rarotonga</option>
				<option value="Pacific/Saipan">Pacific/Saipan</option>
				<option value="Pacific/Samoa">Pacific/Samoa</option>
				<option value="Pacific/Tahiti">Pacific/Tahiti</option>
				<option value="Pacific/Tarawa">Pacific/Tarawa</option>
				<option value="Pacific/Tongatapu">Pacific/Tongatapu</option>
				<option value="Pacific/Truk">Pacific/Truk</option>
				<option value="Pacific/Wake">Pacific/Wake</option>
				<option value="Pacific/Wallis">Pacific/Wallis</option>
				<option value="Pacific/Yap">Pacific/Yap</option>
			</optgroup>

			<optgroup label="Other">
				<option value="UTC">UTC/UTC</option>
				<option value="WET">WET</option>
			</optgroup>
		</Select>
	);
}
