import { useReplyToCalendarEventInvitationMutation } from '@/queries/calendar-event-invitation.queries';
import { ICalendarEventInvitation } from '@/types/calendar-event-invitation/calendar-event-invitation';

interface IArgs {
	invitation: ICalendarEventInvitation;
}

export function useInvitationActionsController(args: IArgs) {
	const replyToInvitationMutation = useReplyToCalendarEventInvitationMutation();

	function handleAcceptInvitationClick() {
		return replyToInvitationMutation.mutateAsync({
			invitationId: args.invitation.id,
			type: 'EventInvitationReplyAccept',
		});
	}

	function handleRejectInvitationClick() {
		return replyToInvitationMutation.mutateAsync({
			invitationId: args.invitation.id,
			type: 'EventInvitationReplyDecline',
		});
	}

	return {
		isLoading: replyToInvitationMutation.isPending,
		handleAcceptInvitationClick,
		handleRejectInvitationClick,
	};
}
