import apiKey from './apiKey';
import auth from './auth';
import availability from './availability.repository';
import booking from './booking.repository';
import config from './config';
import consent from './consent';
import event from './eventType/event-type.repository';
import google from './google';
import organizations from './organizations';
import resourceGroups from './resourceGroups';
import resources from './resources';
import rootPages from './rootPages';
import user from './user.api';

export default {
	apiKey,
	auth,
	availability,
	booking,
	config,
	consent,
	event,
	google,
	organizations,
	resourceGroups,
	resources,
	rootPages,
	user,
};
