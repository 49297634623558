function identify(args: { email: string }) {
	if (!window.Beacon) {
		return;
	}
	return window.Beacon('identify', args);
}

function toggle() {
	if (!window.Beacon) {
		return;
	}
	return window.Beacon('toggle');
}

function open() {
	if (!window.Beacon) {
		return;
	}
	return window.Beacon('open');
}

export default {
	identify,
	open,
	close,
	toggle,
};
