import { EP_API_KEY } from '@/constants/endpoints';
import IApiKey from '@/types/IApiKey';
import client from '../api_client/client';

interface IListApiKeysResponse {
	apiKeys: IApiKey[];
}

export async function list(): Promise<IListApiKeysResponse> {
	return client.get(EP_API_KEY).then((res) => res.data);
}

export async function create() {
	return client.post(EP_API_KEY, {}).then((res) => res.data);
}

export default { list, create };
