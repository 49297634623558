import IEventType from '@/types/IEventType';
import Question from '@/types/IQuestion';

export function encodeQuestions(questions: Array<Question>): Array<Question> {
	return questions;
}

export function decodeQuestions(serverQuestions?: Array<Question>): Array<Question> {
	if (!serverQuestions) {
		return [];
	}
	// Server parses to uppercase, we use lowercase
	return serverQuestions.map((q) => {
		return q;
	});
}

export function decodeTags(tags?: Record<string, string>): IEventType['tags'] {
	if (!tags || !tags.ttStepMode) {
		return { ...tags, ttStepMode: 'auto' };
	}

	return { ...tags, ttStepMode: tags.ttStepMode as 'auto' | 'manual' };
}

export default {
	decodeQuestions,
	encodeQuestions,
	decodeTags,
};
