import libPhoneNumber, { PhoneNumber } from 'libphonenumber-js';
import React, { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';

interface IUsePhoneQuestionArgs {
	label: string;
	name?: string;
	onChange: (e: ChangeEvent<HTMLInputElement | Element>) => void;
	onBlur?: (e: ChangeEvent<HTMLInputElement | Element>) => void;
	isLoading?: boolean;
	value: string;
	error?: string;
	isInvalid?: boolean;
}

export const PHONE_PREFIXES = [
	{ value: '34', label: '🇪🇸 +34' },
	{ value: '52', label: '🇲🇽 +52' },
];

export function usePhoneQuestion(args: IUsePhoneQuestionArgs) {
	const value = args.value || '';
	const { t } = useTranslation();
	const parsed = libPhoneNumber(value);
	const prefix = getPrefix(value, parsed);
	const number = parsed?.nationalNumber || value.replace(prefix, '').replace('+', '');

	function handleNumberChange(e: React.ChangeEvent<HTMLInputElement>) {
		if (!prefix) {
			args.onChange(e);
			return;
		}
		const value = '+' + String(prefix) + String(e.target.value);
		args.onChange({ ...e, target: { ...e.target, value } });
	}

	function handlePrefixChange(e: React.ChangeEvent<HTMLSelectElement>) {
		const value = '+' + String(e.target.value) + String(number);
		args.onChange({ ...e, target: { ...e.target, value } });
	}

	return {
		t,
		parsed,
		prefix,
		number,
		handleNumberChange,
		handlePrefixChange,
	};
}

function getPrefix(value: string, parsed: PhoneNumber | undefined): string {
	if (!parsed) {
		// If value starts with some know prefix return it
		const prefix = PHONE_PREFIXES.find((p) => value.startsWith('+' + p.value));
		if (prefix) {
			return prefix.value;
		}
		// Guess the prefix based on the browser language
		if (navigator.language.includes('es')) {
			return '34';
		}
		if (navigator.language === 'es-MX') {
			return '52';
		}
		if (navigator.language === 'es') {
			return '34';
		}
		return '';
	}
	return parsed.countryCallingCode;
}
