import { IOrganization } from '@/types/IOrganization';
import { IListOrgMembersResponse } from '@/types/apiClient/IListOrgMembersResponse';
import { IListOrganizationsResponse } from '@/types/apiClient/IListOrganizationsResponse';
import client from '../api_client/client';

function list(): Promise<IListOrganizationsResponse> {
	// return Promise.resolve(dummy_list_organizations_response);
	return client.get('/organizations').then((res) => res.data);
}

interface ICreateArgs extends Partial<IOrganization> {
	id: string;
}

function create(args: ICreateArgs): Promise<IListOrganizationsResponse> {
	// return Promise.resolve(dummy_list_organizations_response);
	return client.put(`/organizations/${args.id}`, args).then((res) => res.data);
}

function listMembers(id: string): Promise<IListOrgMembersResponse> {
	return client.get(`/organizations/${id}/members`).then((res) => res.data);
}

interface IAddMemberArgs {
	organizationId: string;
	email: string;
	roles: string[];
}

function inviteMember(args: IAddMemberArgs) {
	return client
		.post(`/organizations/${args.organizationId}/invitations`, {
			email: args.email,
			roles: args.roles,
		})
		.then((res) => res.data);
}

interface IRemoveMemberArgs {
	memberId: string;
	orgId: string;
}

function removeMember(args: IRemoveMemberArgs) {
	return client.delete(`/organizations/${args.orgId}/members/${args.memberId}`).then((res) => res.data);
}

interface IAcceptInvitationArgs {
	code: string;
}

function acceptInvitation(args: IAcceptInvitationArgs) {
	return client.post('/accepted-org-invitations', args).then((res) => res.data);
}

function deleteOrganization(id: string) {
	return client.delete(`/organizations/${id}`).then((res) => res.data);
}

export default {
	acceptInvitation,
	inviteMember,
	create,
	deleteOrganization,
	list,
	listMembers,
	removeMember,
};
