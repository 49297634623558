import calendarEventInvitationRepository from "@/repositories/calendar-event-invitation.repository";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

type IUseGetCalendarEventsArgs = Parameters<typeof calendarEventInvitationRepository.get>[0];

export function useGetCalendarEventInvitationQuery(args: IUseGetCalendarEventsArgs) {
	return useQuery({
		queryKey: ['calendar-event-invitation', 'get', args],
		queryFn: () => calendarEventInvitationRepository.get(args),
	})
}


export function useReplyToCalendarEventInvitationMutation() {
	const client = useQueryClient();

	return useMutation({
		mutationFn: calendarEventInvitationRepository.reply,
		onSuccess() {
			client.invalidateQueries({ queryKey: ['calendar-event-invitation']});
			client.invalidateQueries({ queryKey: ['calendar-event']});
		}
	})
}
