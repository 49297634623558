import client from '@/api_client/client';
import { ICalendarEventInvitation } from '@/types/calendar-event-invitation/calendar-event-invitation';

interface IGetArgs {
	id: string;
}

export function get(args: IGetArgs) {
	return client.get<ICalendarEventInvitation>(`calendar-event-invitations/${args.id}`).then((res) => res.data);
}

interface IReplyArgs {
	invitationId: string;
	type: 'EventInvitationReplyAccept' | 'EventInvitationReplyDecline';
}
export function reply(args: IReplyArgs) {
	return client.post(`calendar-event-invitations/${args.invitationId}/replies`, { type: args.type });
}

export default { get, reply };
