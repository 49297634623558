import { FormControl, FormErrorMessage, FormLabel, Input, InputGroup, InputLeftAddon, Select } from '@chakra-ui/react';
import { ChangeEvent } from 'react';
import { PHONE_PREFIXES, usePhoneQuestion } from './phone-question.controller';

interface IFormQuestionProps {
	id?: string;
	label: string;
	name?: string;
	onChange: (e: ChangeEvent<HTMLInputElement | Element>) => void;
	onBlur?: (e: ChangeEvent<HTMLInputElement | Element>) => void;
	isLoading?: boolean;
	value: string;
	error?: string;
	isInvalid?: boolean;
}

export default function PhoneQuestion(props: IFormQuestionProps) {
	const { t, prefix, number, handleNumberChange, handlePrefixChange } = usePhoneQuestion(props);

	return (
		<FormControl isInvalid={props.isInvalid} mt={4}>
			<FormLabel htmlFor={props.label}>{props.label}</FormLabel>
			<InputGroup variant="filled">
				<InputLeftAddon pr={0}>
					<Select
						aria-label={props.label + ' ' + t('prefix')}
						borderColor="transparent"
						value={prefix}
						onChange={handlePrefixChange}
						variant="unstyled"
						isInvalid={props.isInvalid}
					>
						<option disabled value="">
							🏳️ {t('Select')}
						</option>
						{PHONE_PREFIXES.map((prefix) => (
							<option key={prefix.value} value={prefix.value}>
								{prefix.label}
							</option>
						))}
					</Select>
				</InputLeftAddon>
				<Input
					aria-label={props.label}
					autoComplete="tel-national"
					isDisabled={props.isLoading || !prefix}
					isInvalid={props.isInvalid}
					name={props.name}
					onChange={handleNumberChange}
					pl={1}
					type="tel"
					value={number}
				/>
			</InputGroup>
			<FormErrorMessage>{props.error}</FormErrorMessage>
		</FormControl>
	);
}
