import { Avatar, Box, Heading, Text } from '@chakra-ui/react';

interface IHeaderProps {
	imageSrc?: string;
	name: string;
	description?: string;
}

export default function Header(props: IHeaderProps) {
	return (
		<Box
			borderBottomWidth={4}
			borderBottomStyle={'solid'}
			borderBottomColor={'primary.500'}
			textAlign={{ base: 'center', lg: 'left' }}
			gridRowStart={{ base: 0, lg: 1 }}
			gridRowEnd={{ base: 1, lg: 1 }}
			gridColumnStart={{ base: 1, lg: 1 }}
			gridColumnEnd={{ base: 1, lg: 3 }}
		>
			<Box
				p={8}
				display="grid"
				gridTemplateRows="auto 1fr"
				gridTemplateColumns={{ base: '1fr', lg: props.imageSrc ? 'auto 1fr' : '1fr' }}
				// Center the box horizontally
			>
				{props.imageSrc ? (
					<Avatar
						m="auto"
						mb={{ base: '2', lg: '0' }}
						mr={{ base: 'auto', lg: '6' }}
						size={{ base: 'lg', lg: 'xl' }}
						src={props.imageSrc}
						loading="lazy"
					/>
				) : null}
				<Box my="auto">
					<Heading as="h1" size="lg" m={0} fontWeight={800}>
						{props.name}
					</Heading>
					<Text as="h2" fontSize="md" m={0}>
						{props.description}
					</Text>
				</Box>
			</Box>
		</Box>
	);
}
