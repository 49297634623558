import AppLoader from '@/components/AppLoader';
import { Text } from '@chakra-ui/react';
import { useLoginWithTokenPageController } from './login-with-token-page.controller';

export default function LoginWithTokenPage() {
	const { query } = useLoginWithTokenPageController();

	if (query.status === 'pending') {
		return <AppLoader />;
	}

	if (query.status === 'error') {
		return <Text>Cannot start the application: Login error</Text>;
	}

	if (query.status === 'success') {
		return <Text>Success! Redirecting to home</Text>;
	}
}
