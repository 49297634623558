import { setToken as setApiClientToken } from '@/api_client/client';
import FirebaseContext from '@/context/FirebaseContext';
import firebaseService from '@/services/firebase.service';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { PropsWithChildren, useEffect, useState } from 'react';

export function FirebaseAuthProvider(props: PropsWithChildren) {
	const client = useQueryClient();
	const [isFirebaseReady, setIsFirebaseReady] = useState(false);

	const query = useQuery({
		queryKey: ['firebase-refetch'],
		queryFn: async () => {
			const token = await firebaseService.getIdToken();
			setApiClientToken(token);
			return token;
		},
		initialData: '',
		enabled: isFirebaseReady,
	});

	useEffect(() => {
		const unsubscribe = firebaseService.onAuthStateChanged(() => {
			setIsFirebaseReady(true);
			client.refetchQueries({
				queryKey: ['firebase-refetch'],
			});
		});

		return unsubscribe;
	}, [client]);

	return (
		<FirebaseContext.Provider value={{ token: query.data, isFirebaseReady }}>{props.children}</FirebaseContext.Provider>
	);
}

export function FirebaseAuthProviderTest(props: React.PropsWithChildren) {
	const state = { token: 'dummy_firebase_token', isFirebaseReady: true };
	return <FirebaseContext.Provider value={state}>{props.children}</FirebaseContext.Provider>;
}
