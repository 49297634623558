import IApplicationConfig from '@/types/IApplicationConfig';
import axios from 'axios';

const CONFIG_URL = _getConfigUrl(window.location.hostname);

async function getConfig(): Promise<IApplicationConfig> {
	const { data } = await axios.get<IApplicationConfig>(CONFIG_URL);

	return data;
}

function _getConfigUrl(hostname: string): string {
	if (localStorage.getItem('DEMO_ACCOUNT') === 'demo') {
		return '/config-demo.json';
	}

	if (hostname.includes('bloobirds')) {
		return '/config-bloobirds.json';
	}

	if (hostname.includes('cluber')) {
		return '/config-cluber.json';
	}

	return '/config.json';
}

export default {
	getConfig,
};
