import ITimeZone from '@/types/ITimeZone';
import { DateTime } from 'luxon';
import { Temporal } from 'temporal-polyfill';

export interface IDay {
	/**
	 * @deprecated
	 */
	dt: DateTime;
	plainDate: Temporal.PlainDate;
	timeZone: ITimeZone;
	/**
	 * @deprecated
	 */
	date: Date;
}

interface IGenerateMonthArgs {
	/**
	 * Month: 1-12
	 */
	month: number;
	/**
	 * Year: 2023
	 */
	year: number;
	/**
	 * The timezone to use
	 */
	timeZone: string;
	/**
	 * The locale to use
	 */
	locale: string;
}

/**
 * Returns 42 numbers with the given days corresponding to the month calendar for the given date.
 * @param date
 */
export function generateMonthDays(args: IGenerateMonthArgs): Array<IDay> {
	const result: Array<IDay> = [];
	const startDate = Temporal.PlainDate.from({ year: args.year, month: args.month, day: 1 });

	// Find the first day of the week in which the month starts
	const startOfCalendar = startDate.subtract({ days: (startDate.dayOfWeek - 1) % 7 });

	for (let i = 0; i < 42; i++) {
		const plainDate = startOfCalendar.add({ days: i });
		const dt = DateTime.fromJSDate(toJSDate(plainDate, args.timeZone));

		result.push({ plainDate, timeZone: args.timeZone, dt, date: dt.toJSDate() });
	}

	return result;
}

interface IFormatDateArgs {
	locale: string;
	date: Date;
	options: Intl.DateTimeFormatOptions;
}

export function formatDate(args: IFormatDateArgs) {
	const dtf = new Intl.DateTimeFormat(args.locale, args.options);
	return dtf.format(args.date);
}

export default {
	generateMonthDays,
	formatDate,
};

function toJSDate(temporalDate: Temporal.PlainDate, timeZone: string): Date {
	// Create a Temporal.PlainDateTime by combining the Temporal.PlainDate with a time.
	// Here, we're using midnight (00:00) as the time.
	const dateTime = temporalDate.toPlainDateTime({ hour: 0, minute: 0, second: 0 });

	// Convert the Temporal.PlainDateTime to a Temporal.ZonedDateTime using the specified timeZone.
	const zonedDateTime = dateTime.toZonedDateTime(timeZone);

	// Finally, convert the Temporal.ZonedDateTime to a JavaScript Date object.
	return new Date(zonedDateTime.epochMilliseconds);
}
