import ITimeZone from '@/types/ITimeZone';

// TODO: Improve this logic, maybe based on browser lang?
const DEFAULT_TIMEZONE = 'Europe/Madrid';

function getTimeZone(): ITimeZone {
	let timeZone;

	try {
		timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone as ITimeZone;
	} catch (err) {
		timeZone = DEFAULT_TIMEZONE as ITimeZone;
	}

	// https://bugs.chromium.org/p/chromium/issues/detail?id=364374
	if (timeZone === 'CET') {
		return 'Europe/Madrid' as ITimeZone;
	}

	return timeZone as ITimeZone;
}

export default {
	getTimeZone,
};
