import client from '@/api_client/client';
import { EP_ROOT_PAGES } from '@/constants/endpoints';
import IRootPage from '@/types/IRootPage';

function list(): Promise<Array<IRootPage>> {
	return client.get<{ publicProfiles: IRootPage[] }>(`${EP_ROOT_PAGES}`).then((res) => res.data.publicProfiles);
}

interface IDetailsArgs {
	id: string;
}

function details(args: IDetailsArgs): Promise<IRootPage> {
	return client.get(`${EP_ROOT_PAGES}/${args.id}`).then((res) => res.data);
}

interface IUpdateOrCreateArgs extends IRootPage {}

function updateOrCreate(args: IUpdateOrCreateArgs) {
	return client.put(`${EP_ROOT_PAGES}/${args.id}`, { ...args }).then((res) => res.data);
}

function remove(args: { id: string }) {
	return client.delete(`${EP_ROOT_PAGES}/${args.id}`);
}

/**
 * Gets the details of a shared public profile.
 *
 * For now this is equivalent to a root page but in the future this might change.
 */
function publicDetails(args: { slug: string }): Promise<IRootPage> {
	return client.get(`shared-public-profiles/${args.slug}`).then((res) => res.data);
}

export default {
	details,
	list,
	publicDetails,
	remove,
	updateOrCreate,
};
