import AppConfigContext from '@/context/AppConfigContext';
import React from 'react';

export default function useApplicationConfig() {
	const context = React.useContext(AppConfigContext);

	return {
		...context,
		hasThirdPartyCalendarsEnabled: context.hasGoogleCalendar || context.hasMicrosoftCalendar,
	};
}
