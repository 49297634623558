import IApplicationConfig from '@/types/IApplicationConfig';

export function parseConfigurationFromQueryParams(
	baseConfig: IApplicationConfig,
	params: URLSearchParams
): IApplicationConfig {
	const config = structuredClone(baseConfig);

	const ttHideToolbar = params.get('tt_hide_sidebar');

	if (ttHideToolbar === 'true') {
		config.hideSidebar = true;
	}

	const ttHideTopBar = params.get('tt_hide_top_bar');

	if (ttHideTopBar === 'true') {
		config.hideTopBar = true;
	}

	const name = params.get('tt_app_name');

	if (name) {
		config.name = name;
	}

	const themeColor = params.get('tt_theme_color');

	if (themeColor) {
		config.theme.ttColor = themeColor;
	}

	return config;
}

export default {
	parseConfigurationFromQueryParams,
};
