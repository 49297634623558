const BASE_URL_PRD = 'https://api.timetime.in/v1/';
// const BASE_URL_PRD = 'http://localhost:8080/v1/';

function getBaseUrl() {
	return BASE_URL_PRD;
}

function build(endpoint: string, baseUrl?: string) {
	if (endpoint.charAt(0) === '/') {
		endpoint = endpoint.substr(1);
	}

	if (baseUrl) {
		return `${baseUrl}${endpoint}`;
	}

	return `${BASE_URL_PRD}${endpoint}`;
}

export default {
	getBaseUrl,
	build,
};
