import { useGetCalendarEventInvitationQuery } from '@/queries/calendar-event-invitation.queries';
import { useParams } from 'react-router-dom';

export function useInvitationDetailsController() {
	const { id } = useParams<{ id: string }>();
	const query = useGetCalendarEventInvitationQuery({ id });

	return {
		query,
	};
}
