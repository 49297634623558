import IResourceGroup from '@/types/IResourceGroup';
import client from '../api_client/client';

async function list(): Promise<{ resourceGroups: IResourceGroup[] }> {
	return client.get('/resource-groups').then((res) => res.data);
}

export interface ICreateArgs {
	id: string;
	name: string;
	resources: Array<{ id: string }>;
	tags?: Record<string, string>;
}

async function create(args: ICreateArgs): Promise<void> {
	return client.put(`/resource-groups/${args.id}`, args);
}

async function details(args: { id: string }): Promise<IResourceGroup> {
	return client.get(`/resource-groups/${args.id}`).then((res) => res.data);
}

async function remove(args: { id: string }): Promise<void> {
	return client.delete(`/resource-groups/${args.id}`).then((res) => res.data);
}

export default {
	create,
	details,
	list,
	remove,
};
