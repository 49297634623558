import { BOOKER_EMAIL_QUESTION_ID } from '@/constants/constants';
import { ILocale } from '@/constants/locales';
import useAuth from '@/hooks/useAuth';
import { DEFAULT_VALUE } from '@/hooks/useBusinessHours';
import api_client from '@/repositories';
import availabilityRepository from '@/repositories/availability.repository';
import previewService from '@/services/preview.service';
import uuidService from '@/services/uuid.service';
import { IEventTypeWrite } from '@/types/apiClient/IEventTypeWrite';
import IEventType from '@/types/IEventType';
import { ICustomQueryArgsRepository } from '@/types/utils/ICustomQueryArgs';
import generateSlug from '@/utils/generateSlug';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { useTranslation } from 'react-i18next';

export const QK_EVENT_TYPES = 'event-types';

export interface IUseListEventTypesQueryArgs extends ICustomQueryArgsRepository<typeof api_client.event.list> {}

export function useListEventTypesQuery(args?: IUseListEventTypesQueryArgs) {
	const auth = useAuth();
	const userId = auth.getCurrentUser().userId;

	return useQuery({
		queryKey: [QK_EVENT_TYPES, 'list', userId],
		queryFn: () => api_client.event.list(userId),
		...args,
	});
}

export interface IUseGetEventTypeQueryArgs extends ICustomQueryArgsRepository<typeof api_client.event.details> {
	id: string;
}

export function useGetEventTypeQuery(args: IUseGetEventTypeQueryArgs) {
	return useQuery({
		...args,
		queryKey: [QK_EVENT_TYPES, 'details', args.id],
		queryFn: () => api_client.event.details(args.id),
	});
}

export function useEditEventTypeMutation() {
	const queryClient = useQueryClient();

	return useMutation<AxiosResponse, AxiosError, IEventTypeWrite>({
		mutationKey: [QK_EVENT_TYPES],
		mutationFn: (eventType: IEventTypeWrite) => api_client.event.update(eventType),
		onSuccess(_, variables) {
			// TODO: Optimistic updates?
			queryClient.invalidateQueries({ queryKey: ['availability'] });
			queryClient.invalidateQueries({ queryKey: [QK_EVENT_TYPES, 'details', variables.id] });
			queryClient.invalidateQueries({ queryKey: [QK_EVENT_TYPES, 'public-info', variables.id] });
			// When an event type is updated we need to update the preview
			previewService.updatePreview();
		},
	});
}

export function useRemoveEventTypeMutation() {
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: ({ id }: { id: string }) => api_client.event.remove(id),
		onSuccess(_, variables) {
			queryClient.removeQueries({ queryKey: [QK_EVENT_TYPES, variables.id] });
			queryClient.removeQueries({ queryKey: ['availability', variables.id] });
			queryClient.invalidateQueries({ queryKey: ['availability'] });
			queryClient.invalidateQueries({ queryKey: [QK_EVENT_TYPES] });
		},
	});
}

export function useCreateEventTypeMutation() {
	const auth = useAuth();
	const { t } = useTranslation();
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: (values?: Partial<IEventType>) => {
			const id = uuidService.v4();
			const questionId = uuidService.v4();
			const slug = generateSlug();

			const service: IEventTypeWrite = {
				description: '',
				duration: 'PT1H',
				minBookingNotice: 'PT1H',
				maxBookingNotice: null,
				id,
				name: t('Meeting with {{name}}', { name: auth.getCurrentUser().username }),
				slug,
				questions: [
					{
						id: BOOKER_EMAIL_QUESTION_ID,
						label: t('Email'),
						required: true,
						type: 'EMAIL',
					},
					{
						id: questionId,
						label: t('Name'),
						required: true,
						type: 'TEXT',
					},
				],
				repeatingAvailability: DEFAULT_VALUE,
				maxConcurrentBookings: null,
				step: 'PT1H',
				hosts: [],
				userId: auth.getCurrentUser().userId,
				thirdPartyCalendars: { toSyncBookings: [], toVerifyAvailability: [] },
				busyIntervals: [],
				location: {
					type: 'FixedLocation',
					freeText: '',
				},
				tags: { ttStepMode: 'auto', ttLayout: 'month' },
			};

			return api_client.event.create({ ...service, ...values });
		},
		onSuccess() {
			queryClient.invalidateQueries({ queryKey: [QK_EVENT_TYPES] });
		},
	});
}

interface IuseGetPublicEventTypeInfoArgs {
	id: string;
	locale: ILocale;
}

export function useGetPublicEventTypeInfo(args: IuseGetPublicEventTypeInfoArgs) {
	return useQuery({
		queryKey: [QK_EVENT_TYPES, 'public-info', args],
		queryFn: () => availabilityRepository.getInfo(args),
	});
}
