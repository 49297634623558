/**
 * THIS IS USED AS SUSPENSE FALLBACK SO IT IS USED WHEN CHAKRA IS NOT LOADED YET.
 */
import style from './AppLoader.module.css';

export default function AppLoader() {
	return (
		<div data-testid="loader" className={style.AppLoader}>
			<div className={style.AppLoader__Inner}></div>
		</div>
	);
}
