import { Box } from '@chakra-ui/react';
import InvitationComponent from './components/invitation-component/invitation-component.view';
import { useInvitationDetailsController } from './invitation-details.controller';

export default function InvitationDetailsPage() {
	const { query } = useInvitationDetailsController();

	if (!query.data) {
		return null;
	}

	return (
		<Box bg="gray.50" w="100dvw" h="100dvh">
			<InvitationComponent invitation={query.data} />
		</Box>
	);
}
