import { QueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';

export const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			retry(failureCount, error) {
				/**
				 * It does not make sense to retry 4xx errors.
				 */
				if (error instanceof AxiosError && error.response?.status?.toString()?.startsWith('4')) {
					return false;
				}
				return failureCount < 3;
			},
		},
	},
});
