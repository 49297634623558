import firebaseService from '@/services/firebase.service';
import { useQuery } from '@tanstack/react-query';

interface IUseLoginWithTokenArgs {
	token: string | null;
}

export function useLoginWithTokenQuery(args: IUseLoginWithTokenArgs) {
	return useQuery({
		queryFn() {
			if (!args.token) {
				return Promise.reject('No token provided');
			}
			return firebaseService.loginWithToken(args.token);
		},
		queryKey: ['login-with-token', args.token],
	});
}
