import { EP_CONSENT } from '@/constants/endpoints';
import client from '../api_client/client';

async function consent(authCode: string) {
	return client.post(EP_CONSENT, { authCode });
}

export default {
	consent,
};
