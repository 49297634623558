import AppLoader from '@/components/AppLoader';
import '@/polyfills/remove-child';
import AppConfigProvider from '@/providers/AppConfigProvider';
import AuthProvider from '@/providers/AuthProvider';
import { FirebaseAuthProvider } from '@/providers/FirebaseProvider';
import ImpersonatedUserProvider from '@/providers/ImpersonatedUserProvider';
import { datadogRum } from '@datadog/browser-rum';
import * as Sentry from '@sentry/react';
import { QueryClientProvider } from '@tanstack/react-query';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import './i18n';
import { queryClient } from './queries/query-client';

/**
 * Register service worker
 */
// navigator.serviceWorker.register('/service-worker.js');

/**
 * Configure mock service worker on local env
 */
if (import.meta.env.MODE === 'development') {
	import('@/mocks/browser').then((module: any) => {
		// module.default.start();
		(window as any).__MSW__STOP = module.default.stop;
		(window as any).__MSW__START = module.default.start;
	});
}

if (import.meta.env.MODE === 'production') {
	Sentry.init({
		dsn: 'https://3b480ac149054c89bf8b460cb3557517@o514989.ingest.sentry.io/5619029',
		integrations: [],

		// We recommend adjusting this value in production, or using tracesSampler
		// for finer control
		tracesSampleRate: 1.0,
		release: 'timetime-app@' + __APP_VERSION__,
	});

	datadogRum.init({
		applicationId: '224ca09c-388d-44a0-add3-72d232d742ce',
		clientToken: 'pub2b486bea5a81cae6d2f98f304162c493',
		site: 'datadoghq.com',
		service: 'app.timetime.in',
		env: 'live',
		version: 'timetime-app@' + __APP_VERSION__,
		traceSampleRate: 100,
		sessionSampleRate: 100,
		trackUserInteractions: true,
		allowedTracingUrls: ['https://api.timetime.in'],
	});

	datadogRum.startSessionReplayRecording();
}

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
	<>
		<Sentry.ErrorBoundary showDialog>
			<React.Suspense fallback={<AppLoader />}>
				<QueryClientProvider client={queryClient}>
					<FirebaseAuthProvider>
						<AppConfigProvider>
							<HelmetProvider>
								<AuthProvider>
									<ImpersonatedUserProvider>
										<BrowserRouter>
											<App />
										</BrowserRouter>
									</ImpersonatedUserProvider>
								</AuthProvider>
							</HelmetProvider>
						</AppConfigProvider>
					</FirebaseAuthProvider>
				</QueryClientProvider>
			</React.Suspense>
		</Sentry.ErrorBoundary>
	</>
);
