import FirebaseContext from '@/context/FirebaseContext';
import React from 'react';

export default function useFirebase() {
	const context = React.useContext(FirebaseContext);
	if (context === undefined) {
		throw new Error('useFirebaseAuth must be used within a FirebaseAuthProvider');
	}
	return context;
}
