import { ILocale } from '@/constants/locales';
import ITimeZone from '@/types/ITimeZone';
import ISlot from '@/types/Slot';
import { useMediaQuery } from '@chakra-ui/react';
import { DateTime } from 'luxon';
import { ChangeEvent } from 'react';
import WeekGridDesktop from './components/week-grid-desktop/week-grid-desktop.view';
import WeekGridMobile from './components/week-grid-mobile/week-grid-mobile.view';

interface IBookingPageGridProps {
	displayOnlyStart: boolean;
	from: DateTime;
	locale: ILocale;
	maxUnitsPerBooking?: number;
	selectedDay: DateTime;
	slots: Array<ISlot>;
	timeZone: ITimeZone;
	unitsLabel?: string;
	unitsValue?: number;
	handleBackClick: () => void;
	handleNextClick: () => void;
	handlePrevClick: () => void;
	onSlotClick: (slot: ISlot) => void;
	handleTimeZoneChange: (v: React.ChangeEvent<HTMLSelectElement>) => void;
	onUnitsChange: (e: ChangeEvent<HTMLSelectElement>) => void;
	onDayClick: (v: DateTime) => void;
}

export default function WeekGrid(props: IBookingPageGridProps) {
	const [isLg] = useMediaQuery('(min-width: 62em)');

	return isLg ? (
		<WeekGridDesktop
			from={props.from}
			locale={props.locale}
			onNextClick={props.handleNextClick}
			onPrevClick={props.handlePrevClick}
			onSlotClick={props.onSlotClick}
			slots={props.slots}
			timeZone={props.timeZone}
			onUnitsChange={props.onUnitsChange}
			maxUnitsPerBooking={props.maxUnitsPerBooking}
			unitsLabel={props.unitsLabel}
			value={props.unitsValue}
			displayOnlyStart={props.displayOnlyStart}
		/>
	) : (
		<WeekGridMobile
			selectedDay={props.selectedDay}
			from={props.from}
			locale={props.locale}
			onDayClick={props.onDayClick}
			onNextClick={props.handleNextClick}
			onPrevClick={props.handlePrevClick}
			onSlotClick={props.onSlotClick}
			slots={props.slots}
			timeZone={props.timeZone}
			onUnitsChange={props.onUnitsChange}
			maxUnitsPerBooking={props.maxUnitsPerBooking}
			unitsLabel={props.unitsLabel}
			value={props.unitsValue}
			displayOnlyStart={props.displayOnlyStart}
		/>
	);
}
