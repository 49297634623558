import { BOOKER_PHONE_QUESTION_ID } from '@/constants/constants';
import { ILocationQuestionOptions, IQuestion } from '@/types/IQuestion';
import { FormControl, FormErrorMessage, FormLabel, Input, Select } from '@chakra-ui/react';
import { UseFormReturn, useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import EmailQuestion from './components/email-question/email-question';
import FormQuestionImage from './components/image-question/image-question.component';
import PhoneQuestion from './components/phone-question/phone-question.component';

interface IFormQuestionProps {
	question: IQuestion;
	form: UseFormReturn<Record<string, string>, any>;
}

export default function FormQuestion(props: IFormQuestionProps) {
	const { t } = useTranslation();
	const { field, fieldState } = useController({
		name: props.question.id,
		control: props.form.control,
		rules: getValidationRules(props.question, t),
	});

	switch (props.question.type) {
		case 'IMAGE':
			return <FormQuestionImage {...props} />;
		case 'TEXT':
			return (
				<FormControl mt="4" isInvalid={!!props.form.formState.errors[props.question.id]}>
					<FormLabel>{props.question.label}</FormLabel>
					<Input
						{...props.form.register(props.question.id, {
							required: {
								value: true,
								message: t('Required'),
							},
						})}
						variant="filled"
					/>
					<FormErrorMessage>{props.form.formState.errors[props.question.id]?.message}</FormErrorMessage>
				</FormControl>
			);
		case 'PHONE':
			return (
				<PhoneQuestion
					label={props.question.label}
					value={field.value}
					onChange={field.onChange}
					isInvalid={fieldState.invalid}
					error={fieldState.error?.message}
				/>
			);
		case 'EMAIL':
			return (
				<EmailQuestion
					name={props.question.id}
					id={props.question.id}
					label={props.question.label}
					help={t('Add multiple emails by separating them with a comma.')}
					onBlur={field.onBlur}
					value={field.value}
					isInvalid={fieldState.invalid}
					error={fieldState.error}
					onChange={(value) => {
						props.form.setValue(props.question.id, value, {
							shouldValidate: true,
							shouldDirty: true,
							shouldTouch: true,
						});
					}}
				/>
			);
		case 'LOCATION':
			return (
				<FormControl mt="4" isInvalid={!!props.form.formState.errors[props.question.id]}>
					<FormLabel>{props.question.label}</FormLabel>
					<Select
						{...props.form.register(props.question.id, {
							required: {
								value: true,
								message: t('Required'),
							},
						})}
						variant="filled"
					>
						{_getAllowedOptions(props.question.allowedOptions).map((option) => {
							return (
								<option key={option.type} value={option.type}>
									{t(option.type)}
								</option>
							);
						})}
					</Select>
					{props.form.watch(props.question.id) === 'BookerPhoneLocationOption' ? (
						<FormQuestion
							question={{
								id: BOOKER_PHONE_QUESTION_ID,
								label: t('Phone'),
								required: true,
								type: 'PHONE',
							}}
							form={props.form}
						/>
					) : null}
				</FormControl>
			);
		default:
			return null;
	}
}

function _getAllowedOptions(options: ILocationQuestionOptions) {
	return options.sort((a, b) => {
		return a.type.localeCompare(b.type);
	});
}

function getValidationRules(question: IQuestion, t: any) {
	if (question.type === 'PHONE') {
		return {
			required: {
				value: true,
				message: t('Required'),
			},
			minLength: {
				value: 8,
				message: t('Invalid'),
			},
		};
	}

	if (question.type === 'EMAIL') {
		return {
			validate: (value: string) => {
				if (!value) {
					return t('Required');
				}
				const emails = value.split(',').map((email) => email.trim());
				const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
				if (!emails.every((email) => emailRegex.test(email))) {
					return t('Invalid email');
				}

				return true;
			},
		};
	}

	return {
		required: {
			value: true,
			message: t('Required'),
		},
	};
}
