import { EP_USER } from '@/constants/endpoints';
import { ILocale } from '@/constants/locales';
import IUser from '@/types/IUser';
import client from '../api_client/client';

export interface IUpdateArgs {
	id: string;
	slug: string;
	timeZone: string;
	locale: ILocale;
	clientState?: string;
}

export function get({ id }: { id: string }): Promise<IUser> {
	return client.get(`${EP_USER}/${id}`).then((raw) => raw.data);
}

export function update({ id, ...options }: IUpdateArgs) {
	return client.put(`${EP_USER}/${id}`, { ...options });
}

interface IListUsersResponse {
	users: IUser[];
}

export function list(): Promise<IListUsersResponse> {
	return client.get(`${EP_USER}`).then((raw) => raw.data);
}

export default { get, update, list };
