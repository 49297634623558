import api_client from '@/repositories';
import appConfigService from '@/services/app-config/app-config.service';
import { ICustomQueryArgsRepository } from '@/types/utils/ICustomQueryArgs';
import { useQuery } from '@tanstack/react-query';

const QK_APP_CONFIG = 'application-config';

type IUseAppConfigArgs = ICustomQueryArgsRepository<typeof api_client.config.getConfig>;

export function useAppConfig(args?: IUseAppConfigArgs) {
	return useQuery({
		queryKey: [QK_APP_CONFIG],
		queryFn: async () => {
			const baseConfig = await api_client.config.getConfig();
			const url = new URL(window.location.href);
			const params = new URLSearchParams(url.search);
			const config = appConfigService.parseConfigurationFromQueryParams(baseConfig, params);
			return config;
		},
		...args,
	});
}
