import { ILocale } from '@/constants/locales';
import dateService from '@/services/date/date.service';
import ITimeZone from '@/types/ITimeZone';
import ISlot from '@/types/Slot';
import { Box, Flex, Tag, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

interface IGridHeaderProps {
	grid: ISlot[][];
	locale: ILocale;
	timeZone: ITimeZone;
}

export default function GridHeader(props: IGridHeaderProps) {
	const { t } = useTranslation();

	const dateFormatMonth = new Intl.DateTimeFormat(props.locale, {
		month: 'short',
		timeZone: props.timeZone,
	});

	const dateFormatDesktopWeekDay = new Intl.DateTimeFormat(props.locale, {
		weekday: 'short',
		timeZone: props.timeZone,
	});

	const dateFormatDesktopDay = new Intl.DateTimeFormat(props.locale, {
		day: '2-digit',
		timeZone: props.timeZone,
	});

	return (
		<Box
			overflow="hidden"
			flexDir="row"
			pb="1"
			gridGap={1}
			display="grid"
			gridTemplateColumns="repeat(7, auto)"
			shadow="sm"
		>
			{props.grid.map((day) => (
				<Box key={day[0].start.toISO()!} px="2" fontFamily="Inter Variable">
					<Box maxW="100%" overflow="auto" px="0">
						<Flex>
							<Text
								fontSize="smaller"
								textTransform="uppercase"
								fontWeight={500}
								color={day[0].start.day === 6 || day[0].start.day === 0 ? 'primary.500' : 'gray.600'}
							>
								{dateFormatDesktopWeekDay.format(day[0].start.toJSDate())}
							</Text>
							{dateService.isToday(day[0].start.toJSDate()) ? (
								<Tag ml="auto" size="sm" variant="solid" colorScheme="primary">
									{t('Today')}
								</Tag>
							) : null}
						</Flex>
						<Flex justifyContent="space-between" alignItems="flex-end">
							<Text fontSize="3xl" fontWeight={800} textTransform="uppercase">
								{dateFormatDesktopDay.format(day[0].start.toJSDate())}
							</Text>
							<Text textTransform="uppercase" fontWeight={300} fontSize="2xs" mb="2" mr="2" color="gray.600">
								{dateFormatMonth.format(day[0].start.toJSDate())}
							</Text>
						</Flex>
					</Box>
				</Box>
			))}
		</Box>
	);
}
