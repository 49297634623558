import client from '../api_client/client';

interface IconsentParams {
	authCode: string;
	provider: 'MICROSOFT';
}

async function consent(params: IconsentParams) {
	return client.post('/consent', { ...params });
}

export default {
	consent,
};
