import AppLoader from '@/components/AppLoader';
import { ChakraProvider } from '@chakra-ui/react';
import '@fontsource-variable/inter';

import {
	ROUTE_BOOKING_DETAILS,
	ROUTE_BOOKING_FORM,
	ROUTE_BOOKING_PAGE,
	ROUTE_DASHBOARD,
	ROUTE_GRID,
	ROUTE_INVITATION_DETAILS,
	ROUTE_LOGIN,
	ROUTE_LOGIN_WITH_TOKEN,
	ROUTE_ONBOARDING,
	ROUTE_ROOT_PAGE_PUBLIC,
	ROUTE_SIGN_UP,
} from '@/constants/routes';

import useApplicationConfig from '@/hooks/useApplicationConfig';
import BookingFormPage from '@/pages/booking-form-page/booking-form.page';
import BookingPage from '@/pages/booking-page/booking-page';
import React, { Suspense } from 'react';
import { Helmet } from 'react-helmet-async';
import { Redirect, Route, Switch } from 'react-router-dom';
import './global.css';
import InvitationDetailsPage from './pages/invitation-details/invitation-details.page';
import LoginWithTokenPage from './pages/login-with-token-page/login-with-token-page.view';
import themeService from './services/theme.service';

/**
 * Lazy load all routes except the public booking page for faster loading
 */
const BookingDetailsPage = React.lazy(() => import('./pages/booking-details-page/booking-details-page.view'));
const DashboardPage = React.lazy(() => import('./pages/DashboardPage/dashboard.page'));
const LoginPage = React.lazy(() => import('./pages/LoginPage/LoginPage'));
const OnboardingPage = React.lazy(() => import('./pages/Onboarding/OnboardingPage'));
const RootPagePublic = React.lazy(() => import('./pages/RootPagePublic/RootPagePublic'));
const SignUpPage = React.lazy(() => import('./pages/Signup/Signup.page'));
const PlayGroundPage = React.lazy(() => import('./pages/PlaygroundPage'));

export default function App() {
	const { name, theme } = useApplicationConfig();
	const customTheme = themeService.customizeTheme(theme);

	return (
		<ChakraProvider theme={customTheme} toastOptions={{ defaultOptions: { position: 'bottom-right' } }}>
			<Helmet>
				<title>{name}</title>
			</Helmet>

			<Suspense fallback={<AppLoader />}>
				<Switch>
					<Route strict exact path={ROUTE_LOGIN}>
						<LoginPage />
					</Route>

					{/* Legacy URL */}
					<Route strict path={ROUTE_GRID}>
						<BookingPage />
					</Route>

					{/* New URL */}
					<Route strict path={ROUTE_BOOKING_PAGE}>
						<BookingPage />
					</Route>

					<Route strict path={ROUTE_DASHBOARD}>
						<DashboardPage />
					</Route>

					<Route path={ROUTE_ONBOARDING}>
						<OnboardingPage />
					</Route>

					<Route strict path={ROUTE_BOOKING_DETAILS}>
						<BookingDetailsPage />
					</Route>

					<Route strict path={ROUTE_ROOT_PAGE_PUBLIC}>
						<RootPagePublic />
					</Route>

					<Route strict path={ROUTE_SIGN_UP}>
						<SignUpPage />
					</Route>

					<Route strict path={ROUTE_BOOKING_FORM}>
						<BookingFormPage />
					</Route>

					<Route path="/playground">
						<PlayGroundPage />
					</Route>

					<Route path={ROUTE_INVITATION_DETAILS}>
						<InvitationDetailsPage />
					</Route>

					<Route path={ROUTE_LOGIN_WITH_TOKEN}>
						<LoginWithTokenPage />
					</Route>

					<Redirect to={ROUTE_DASHBOARD} />
				</Switch>
			</Suspense>
		</ChakraProvider>
	);
}
