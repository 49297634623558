import { Box, ButtonGroup, FormControl, FormLabel, IconButton, Select } from '@chakra-ui/react';
import { DateTime } from 'luxon';
import { BsChevronLeft, BsChevronRight } from 'react-icons/bs';

interface IBookingPageNavProps {
	handleNextClick: () => void;
	handlePrevClick: () => void;
	handleUnitsChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
	maxUnitsPerBooking?: number;
	unitsLabel?: string;
	value?: number;
	from: DateTime;
	locale: string;
}

export default function DesktopGridNavigation(props: IBookingPageNavProps) {
	return (
		<Box px={1} my={4} display="flex" alignItems="center" justifyContent="space-between">
			{props.maxUnitsPerBooking ? (
				<FormControl maxW="xs">
					<FormLabel>{props.unitsLabel}</FormLabel>
					<Select value={props.value} onChange={props.handleUnitsChange}>
						{_generateOptions(props.maxUnitsPerBooking)}
					</Select>
				</FormControl>
			) : null}

			<ButtonGroup isAttached ml="auto">
				<IconButton onClick={props.handlePrevClick} aria-label="prev week" icon={<BsChevronLeft />}></IconButton>
				<IconButton onClick={props.handleNextClick} aria-label="next week" icon={<BsChevronRight />}></IconButton>
			</ButtonGroup>
		</Box>
	);
}

function _generateOptions(range: number) {
	const options = [];
	for (let i = 1; i <= range; i++) {
		options.push(
			<option key={i} value={i}>
				{i}
			</option>
		);
	}
	return options;
}
