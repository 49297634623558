import useApplicationConfig from '@/hooks/useApplicationConfig';
import { Text, TextProps } from '@chakra-ui/react';
import { t } from 'i18next';

export function TimeTimeBanner(props: TextProps) {
	const appConfig = useApplicationConfig();

	return (
		<Text {...props}>
			{t('Website created using')} <strong style={{ fontWeight: 900 }}>{appConfig.name}</strong>
		</Text>
	);
}
