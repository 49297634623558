import { extendTheme } from '@chakra-ui/react';
import colors from './colors';

const timetimeTheme = extendTheme({
	fonts: { heading: `"Inter Variable", sans-serif`, body: `"Inter Variable", sans-serif` },
	styles: {
		global: {
			body: {
				bg: '#f9f9fa',
			},
			'nav a.active': {
				bg: 'gray.100',
				color: 'black',
			},
		},
	},
	colors: {
		...colors,
		primary: colors.pink,
	},
});

export default timetimeTheme;
