interface IBuildSearchParamsArgs {
	params: URLSearchParams | Record<string, string | number>;
	originalParams?: URLSearchParams | string;
}

/**
 * Helper that builds an URLSearchParams preserving the existing values.
 */
function buildSearchParams(args: IBuildSearchParamsArgs): string {
	const params = args.originalParams ? new URLSearchParams(args.originalParams) : new URLSearchParams();

	Object.entries(args.params).forEach(([key, value]) => {
		params.set(key, value.toString());
	});

	return params.toString();
}

export default {
	buildSearchParams,
};
