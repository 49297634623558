import { Box, FormControl, FormErrorMessage, FormLabel, Input } from '@chakra-ui/react';
import { t } from 'i18next';
import React, { useState } from 'react';
import { FieldError } from 'react-hook-form';
import Emails from './components/emails';

interface IEmailInputProps {
	error?: FieldError | undefined;
	help?: string;
	id: string;
	isInvalid: boolean;
	label: string;
	name: string;
	value: string;
	onChange: (v: string) => void;
	onBlur: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const EmailQuestion = React.forwardRef<HTMLInputElement, IEmailInputProps>((props, ref) => {
	const initialEmails = props.value.split(',').filter(Boolean) || [];
	const [inputValue, setInputValue] = useState('');
	const [emails, setEmails] = useState<string[]>(initialEmails);

	// Add an email to the list, if it's valid and isn't already there.
	const addEmails = (emailsToAdd: string[]) => {
		const validatedEmails = emailsToAdd.map((e) => e.trim()).filter(Boolean);

		const newEmails = [...emails, ...validatedEmails];

		setEmails(newEmails);
		setInputValue('');

		props.onChange(newEmails.join(','));
	};

	// Remove an email from the list.
	const removeEmail = (email: string) => {
		const index = emails.findIndex((e) => e === email);
		const newEmails = [...emails];
		if (index !== -1) {
			newEmails.splice(index, 1);
			setEmails(newEmails);
		}
		props.onChange(newEmails.join(','));
	};

	// Save input field contents in state when changed.
	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setInputValue(e.target.value);
	};

	// Validate and add the email if we press tab, enter or comma.
	const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
		if ([',', ' '].includes(e.key)) {
			e.preventDefault();
			addEmails([inputValue]);
			return;
		}
		if (['Enter', 'Tab'].includes(e.key)) {
			addEmails([inputValue]);
		}
	};

	// Split and add emails when pasting.
	const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
		e.preventDefault();

		const pastedData = e.clipboardData.getData('text');
		const pastedEmails = pastedData.split(',');
		addEmails(pastedEmails);
	};

	const handleCloseClick = (email: string) => {
		removeEmail(email);
	};

	function handleBlur(e: React.ChangeEvent<HTMLInputElement>) {
		// Save current form status and call props.onBlur
		addEmails([e.target.value.trim()]);
	}

	return (
		<FormControl mt={4} id={props.name + '_input'} isInvalid={props.isInvalid}>
			<FormLabel htmlFor={props.name + '_input'}>{props.label}</FormLabel>
			<Box
				position="relative"
				display="flex"
				w="full"
				overflow="hidden"
				flexWrap="wrap"
				bg="gray.100"
				px={2}
				py={1}
				borderRadius="md"
				_hover={{
					bg: 'gray.200',
				}}
				// When some child has focus add a border
				_focusWithin={{
					outline: 'solid',
					outlineWidth: 2,
					outlineOffset: -2,
					outlineColor: 'blue.400',
				}}
				outline="solid"
				style={{ outlineWidth: 2, outlineOffset: -2 }}
				outlineColor={props.isInvalid ? 'red.400' : 'transparent'}
				__css={{
					'input:invalid': {
						outlineWidth: 2,
						outlineColor: 'red.400',
					},
				}}
			>
				<Emails emails={emails} onCloseClick={handleCloseClick} />
				<Input
					my={1}
					w="auto"
					type="email"
					ref={ref}
					name={props.name + '_input'}
					px={2}
					variant="unstyled"
					onPaste={handlePaste}
					onKeyDown={handleKeyDown}
					onChange={handleChange}
					onBlur={handleBlur}
					value={inputValue}
					placeholder={emails.length === 0 ? t('Write your email') : t('Add another email')}
					autoComplete="email"
					borderRadius="sm"
				/>
			</Box>
			<FormErrorMessage>{props.error?.message}</FormErrorMessage>
		</FormControl>
	);
});

export default EmailQuestion;
