import { MIN_SLOT_SCORE } from '@/constants/constants';
import ISlot from '@/types/Slot';
import { DateTime } from 'luxon';

interface ICheckAvailabilityArgs {
	from: DateTime;
	to: DateTime;
	timeZone: string;
	slots: Array<ISlot>;
}

export function checkAvailability(args: ICheckAvailabilityArgs): boolean {
	return args.slots.some((slot) => {
		if (slot.score < MIN_SLOT_SCORE) {
			return false;
		}

		const startUTC = slot.start.toJSDate().toUTCString();
		const endUTC = slot.end.toJSDate().toUTCString();

		const fromUTC = args.from.toJSDate().toUTCString();
		const toUTC = args.to.toJSDate().toUTCString();

		return startUTC === fromUTC && endUTC === toUTC;
	});
}
