export const EP_API_KEY = 'api-keys';
export const EP_AVAILABILITY = 'event-types';
export const EP_BOOKINGS = 'bookings';
export const EP_CONSENT = 'google-consent';
export const EP_EVENT = 'event-types';
export const EP_EVENTS = 'event-types';
export const EP_LOGIN = 'login';
export const EP_ROOT_PAGES = 'public-profiles';
export const EP_USER = 'users';
export const EP_PRICING_POLICIES = 'pricing-policies';
