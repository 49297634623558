import timetimeTheme from '@/themes';
import { IApplicationTheme } from '@/types/IApplicationTheme';
import { extendTheme } from '@chakra-ui/react';

export function customizeTheme(applicationTheme: IApplicationTheme): Record<string, any> {
	const theme = extendTheme(applicationTheme, timetimeTheme);

	theme.colors = { ...theme.colors, primary: theme.colors[applicationTheme.ttColor] };
	return theme;
}

export function customizeWithPrimaryColor(theme: Record<string, any>, color: string): Record<string, any> {
	return {
		...theme,
		colors: { ...theme.colors, primary: theme.colors[color] },
	};
}

export default { customizeTheme, customizeWithPrimaryColor };
