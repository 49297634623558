import AppLoader from '@/components/AppLoader';
import { Box, ChakraProvider, Container, FormControl, FormLabel, Grid, Select } from '@chakra-ui/react';
import DaySlots from './components/DaySlots/DaySlots';
import Header from './components/Header/Header';
import Month from './components/Month/Month';
import useBookingPage from './useBookingPage2';

export default function BP2() {
	const {
		availabilityQuery,
		daySlots,
		from,
		query,
		selectedDay,
		theme,
		timeZone,
		isLoading,
		units,
		locale,
		handleLocaleChange,
		handleSlotClick,
		handleNextClick,
		handlePrevClick,
		handleTimeZoneChange,
		handleDayClick,
		handleUnitsChange,
	} = useBookingPage();

	if (!query.data) {
		return <AppLoader />;
	}

	return (
		<ChakraProvider theme={theme}>
			<Box w="100dvw" h="100dvh" overflow="auto" display="grid">
				<Container
					opacity={isLoading ? 0.5 : 1}
					cursor={isLoading ? ' progress' : 'auto'}
					p={0}
					m="auto"
					bg="white"
					w="100%"
					h="100%"
					maxH={{ base: '100%', lg: 'container.md' }}
					maxW={{ base: '100%', lg: 'container.lg' }}
					minW={{ base: '100%', md: 'container.sm', lg: 'container.md', xl: 'container.lg' }}
					display="grid"
					gridTemplateRows={{ base: 'auto 1fr auto', lg: 'auto 1fr' }}
					gridTemplateColumns={{ base: '1fr', lg: '1fr' }}
					borderWidth={1}
					borderColor="gray.200"
					borderRadius={{ base: 0, lg: 'md' }}
					mx={{ base: '', md: 'auto' }}
					my={{ lg: 'auto' }}
					overflow={{ base: 'auto', lg: 'auto' }}
				>
					<Header name={query.data.name} description={query.data.description} imageSrc={query.data.imageUrl ?? ''} />

					{availabilityQuery.data ? (
						<Grid id="bottom" overflow="auto" w="full" gridTemplateColumns={{ base: '1fr', lg: '3fr 1fr' }}>
							<Grid borderRight={{ base: 'none', lg: '4px solid var(--chakra-colors-primary-500)' }}>
								{query.data.maxUnitsPerBooking ? (
									<FormControl pl={{ base: 4, md: 8 }} my={4} maxW="xs">
										<FormLabel>{query.data.unitsLabel}</FormLabel>
										<Select value={units} onChange={handleUnitsChange}>
											{_generateOptions(query.data.maxUnitsPerBooking)}
										</Select>
									</FormControl>
								) : null}

								<Month
									availableLocales={query.data.i18n.availableLocales}
									locale={locale}
									timeZone={timeZone}
									onLocaleChange={handleLocaleChange}
									onNextClick={handleNextClick}
									onPrevClick={handlePrevClick}
									onTimeZoneChange={handleTimeZoneChange}
									onDayClick={handleDayClick}
									month={from.month}
									year={from.year}
									selectedDay={selectedDay}
									slots={availabilityQuery.data.timeSlots}
								/>
							</Grid>

							<DaySlots
								locale={locale}
								displayOnlyStart={query.data.eventTypeTags?.ttIntervalView !== 'range'}
								timeZone={timeZone}
								onSlotClick={handleSlotClick}
								selectedDay={selectedDay}
								slots={daySlots}
							/>
						</Grid>
					) : null}
				</Container>
			</Box>
		</ChakraProvider>
	);
}

function _generateOptions(range: number) {
	const options = [];
	for (let i = 1; i <= range; i++) {
		options.push(
			<option key={i} value={i}>
				{i}
			</option>
		);
	}
	return options;
}
