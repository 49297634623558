import calendarService from '@/services/calendar/calendar.service';
import { Box, IconButton, Text } from '@chakra-ui/react';
import { DateTime } from 'luxon';
import { BiChevronLeft, BiChevronRight } from 'react-icons/bi';

interface INavProps {
	lang: string;
	month: number;
	year: number;
	onPrev: () => void;
	onNext: () => void;
}
export default function Nav(props: INavProps) {
	const date = DateTime.fromObject({
		month: props.month,
		year: props.year,
		day: 1,
	});

	return (
		<Box
			mb="2"
			display="grid"
			gridTemplateColumns="auto 1fr auto"
			gridColumnStart="1"
			gridColumnEnd="8"
			justifyContent="center"
			alignItems={'center'}
		>
			<IconButton aria-label="Prev" icon={<BiChevronLeft />} variant="ghost" onClick={props.onPrev} />
			<Text fontFamily="Inter Variable" fontWeight="600" textTransform="capitalize">
				{calendarService.formatDate({
					date: date.toJSDate(),
					locale: props.lang,
					options: {
						month: 'long',
						year: 'numeric',
					},
				})}
			</Text>
			<IconButton aria-label="next" icon={<BiChevronRight />} variant="ghost" onClick={props.onNext} />
		</Box>
	);
}
