import { ILocale } from '@/constants/locales';
import availabilityRepository from '@/repositories/availability.repository';
import { useQuery } from '@tanstack/react-query';
import { DateTime } from 'luxon';

interface IUseAvailabilityParams {
	id: string;
	from: DateTime;
	units?: number;
	days?: number;
	locale?: ILocale;
}

export function useAvailability(params: IUseAvailabilityParams) {
	return useQuery({
		queryKey: [
			'availability',
			{
				days: params.days,
				from: params.from.toISODate()!,
				id: params.id,
				tz: params.from.zone,
				units: params.units,
				locale: params.locale,
			},
		],
		queryFn: () => availabilityRepository.get(params),
	});
}
