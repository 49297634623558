import { generateMonthDays, IDay } from '@/services/calendar/calendar.service';
import dateService from '@/services/date/date.service';
import ITimeZone from '@/types/ITimeZone';
import ISlot from '@/types/Slot';
import { Box } from '@chakra-ui/react';
import { CalendarDay } from './components/Day';
import Header from './components/Header';
import Nav from './components/Nav';

interface ICalendarProps {
	selected: Date | undefined;
	slots: Array<ISlot>;
	/**
	 * 1-12
	 */
	month: number;
	/**
	 * 2021...
	 */
	year: number;
	onPrev: () => void;
	onNext: () => void;
	onDayClick: (day: IDay) => void;
	locale: string;
	timeZone: ITimeZone;
}

export default function Calendar(props: ICalendarProps) {
	const lang = props.locale || 'en';

	const days = generateMonthDays({
		month: props.month,
		year: props.year,
		timeZone: props.timeZone,
		locale: props.locale,
	});

	return (
		<Box
			margin="auto"
			borderRadius="md"
			display="grid"
			gridTemplateAreas={`
			'nav nav nav nav nav nav nav'
			'h1 h2 h3 h4 h5 h6 h7'
			'd1 d2 d3 d4 d5 d6 d7'
			'd8 d9 d10 d11 d12 d13 d14'
			'd15 d16 d17 d18 d19 d20 d21'
			'd22 d23 d24 d25 d26 d27 d28'
			'd29 d30 d31 d32 d33 d34 d35'
			'd36 d37 d38 d39 d40 d41 d42'
			`}
			gridGap={{
				base: 2,
				sm: 2,
				md: 2,
			}}
			justifyContent="center"
			alignItems="center"
			textAlign="center"
		>
			<Nav lang={lang} month={props.month} year={props.year} onPrev={props.onPrev} onNext={props.onNext} />

			<Header lang={lang} firstDayOffTheMonth={days[0].dt} />

			{days.map((day) => {
				let hasAvailableSlots = false;

				for (let i = 0; i < props.slots.length; i++) {
					const slot = props.slots[i];

					if (slot.score <= 0) {
						continue;
					}

					if (dateService.isSameDay(day.date, slot.start.toJSDate(), props.timeZone)) {
						hasAvailableSlots = true;
						break;
					}
				}

				return (
					<CalendarDay
						day={day}
						timeZone={props.timeZone}
						onClick={props.onDayClick}
						key={day.date.getTime()}
						date={day.date}
						isCurrentMonth={day.plainDate.month === props.month}
						isToday={dateService.isToday(day.date)}
						isWeekend={dateService.isWeekend(day.date)}
						isInThePast={dateService.isInThePast(day.date)}
						isSelected={props.selected ? dateService.isSameDay(day.date, props.selected, props.timeZone) : false}
						isDisabled={!hasAvailableSlots}
					/>
				);
			})}
		</Box>
	);
}
