import timeZones from '@/constants/timeZones';
import ITimeZone from '@/types/ITimeZone';
import { FormControl } from '@chakra-ui/react';
import { Select, SizeProp } from 'chakra-react-select';
import React from 'react';

interface ITimeZoneSelectProps {
	onChange: React.ChangeEventHandler<HTMLSelectElement>;
	value: ITimeZone;
	isDisabled?: boolean;
	size?: SizeProp | 'xs';
}

const options = timeZones.map((t) => ({
	label: t as ITimeZone,
	value: t as ITimeZone,
}));

export default function TimeZoneSelector(props: ITimeZoneSelectProps) {
	return (
		<FormControl id="timezone">
			<Select
				size={(props.size as any) ?? 'md'}
				isDisabled={props.isDisabled}
				onChange={(v) => {
					if (!v) {
						return;
					}
					props.onChange({ target: { value: v.value } } as any);
				}}
				value={{ label: props.value, value: props.value }}
				options={options}
			/>
		</FormControl>
	);
}
