import { QueryClient } from '@tanstack/react-query';
import { useEffect, useState } from 'react';

interface IArgs {
	queryClient: QueryClient;
}

/**
 * Message emitted when the host page starts updating the booking page configuration.
 */
export const START_UPDATE_MESSAGE = 'booking-page-update-start';
/**
 * Message emitted when the host page starts finishes updating the booking page configuration.
 * When this happens the booking page can refetch the data and display the updated UI.
 */
export const END_UPDATE_MESSAGE = 'booking-page-update-end';

/**
 * This hook is used to communicate the booking page with the host page when is hosted on an iframe.
 */
export default function useBookingPagePreview(args: IArgs) {
	const [isPreviewLoading, setIsPreviewLoading] = useState(false);

	useEffect(() => {
		window.addEventListener(
			'message',
			(event) => {
				if (event.origin !== window.location.origin) {
					return;
				}
				if (event.data.type === START_UPDATE_MESSAGE) {
					setIsPreviewLoading(true);
				}
				if (event.data.type === END_UPDATE_MESSAGE) {
					args.queryClient.invalidateQueries().finally(() => {
						setIsPreviewLoading(false);
					});
				}
			},
			false
		);
	}, [args.queryClient]);

	return { isPreviewLoading };
}
