import client from '@/api_client/client';
import { EP_LOGIN } from '@/constants/endpoints';
import LoginResponse from '@/types/LoginResponse';


export async function login() {
	return client.post<LoginResponse>(EP_LOGIN).then((res) => res.data);
}

export default { login };
