import ILoginProvider from '@/types/ILoginProvider';
import { initializeApp } from 'firebase/app';
import {
	GoogleAuthProvider,
	OAuthProvider,
	User,
	browserLocalPersistence,
	browserPopupRedirectResolver,
	onAuthStateChanged as fbOnAuthStateChanged,
	getAuth,
	initializeAuth,
	signInWithCustomToken,
	signInWithEmailAndPassword,
	signInWithPopup,
} from 'firebase/auth';

export const MS_OAUTH_PROVIDER = new OAuthProvider('microsoft.com');
export const GOOGLE_OAUTH_PROVIDER = new GoogleAuthProvider();

const app = initializeApp({
	apiKey: 'AIzaSyDE6VLS7DtThux_9-X3lxDyzeYq8JTBEOQ',
	authDomain: 'auth.timetime.in',
	databaseURL: 'https://timetime-id.firebaseio.com',
	projectId: 'timetime-id',
	storageBucket: 'timetime-id.appspot.com',
	messagingSenderId: '338732364255',
	appId: '1:338732364255:web:e12014d18505e14b69946a',
	measurementId: 'G-M417MYBQQX',
});

/**
 * initializeAuth does not work on testing environment so we fallback to getAuth.
 * Using initialize auth is a performance improvement for production.
 * - https://firebase.google.com/docs/auth/web/custom-dependencies
 */
export const auth =
	process.env.NODE_ENV === 'test'
		? getAuth(app)
		: initializeAuth(app, {
			persistence: browserLocalPersistence,
			popupRedirectResolver: browserPopupRedirectResolver,
		});

function onAuthStateChanged(callback: (user: User | null) => void) {
	return fbOnAuthStateChanged(auth, callback);
}

function logout() {
	return auth.signOut();
}

function login(provider: ILoginProvider) {
	switch (provider) {
		case 'google':
			return signInWithPopup(auth, GOOGLE_OAUTH_PROVIDER);
		case 'microsoft':
			return signInWithPopup(auth, MS_OAUTH_PROVIDER);
	}
}

function loginWithEmailAndPassword(email: string, password: string) {
	return signInWithEmailAndPassword(auth, email, password);
}

function loginWithToken(token: string) {
	return signInWithCustomToken(auth, token);
}

async function getIdToken(): Promise<string> {
	const token = await auth.currentUser?.getIdToken();
	return token || '';
}

export default { onAuthStateChanged, logout, login, loginWithEmailAndPassword, getIdToken, auth, loginWithToken };
