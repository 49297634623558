import urlService from '@/services/url.service';
import IUser from '@/types/IUser';
import axios from 'axios';

const client = axios.create({
	baseURL: urlService.getBaseUrl(),
});

export function setToken(token: string) {
	client.defaults.headers.common['Authorization'] = `Bearer: ${token}`;
	sessionStorage.setItem('auth-token', token);
}

export function impersonate(user?: IUser) {
	if (user?.userId) {
		return (client.defaults.headers.common['X-TT-Impersonated-User-Id'] = user.userId);
	}

	delete client.defaults.headers.common['X-TT-Impersonated-User-Id'];
}

export default client;
