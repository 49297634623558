import businessHoursService from '@/services/businessHours.service';
import timezoneService from '@/services/timezone.service';
import IBusinessHours, { IBusinessHour, IDayID, IWeekly } from '@/types/BusinessHours';
import ITimeZone from '@/types/ITimeZone';
import copy from '@/utils/copy';
import React from 'react';

export const EMPTY_VALUE: IWeekly = {
	SUNDAY: [],
	MONDAY: [],
	TUESDAY: [],
	WEDNESDAY: [],
	THURSDAY: [],
	FRIDAY: [],
	SATURDAY: [],
};

export const DEFAULT_VALUE: IBusinessHours = {
	timeZone: timezoneService.getTimeZone(),
	weekly: {
		SUNDAY: [],
		MONDAY: [{ start: '09:00', end: '18:00' }],
		TUESDAY: [{ start: '09:00', end: '18:00' }],
		WEDNESDAY: [{ start: '09:00', end: '18:00' }],
		THURSDAY: [{ start: '09:00', end: '18:00' }],
		FRIDAY: [{ start: '09:00', end: '18:00' }],
		SATURDAY: [],
	},
};

interface IUseBusinessHoursProps {
	businessHours: IBusinessHours;
	onChange: (value: IBusinessHours) => void;
}

export default function useBusinessHours(props: IUseBusinessHoursProps) {
	const [businessHours, setBusinessHours] = React.useState<IBusinessHours>(props.businessHours || DEFAULT_VALUE);

	function handleTimeZoneChange(value: ITimeZone) {
		const businessHoursCopy = copy(businessHours);
		businessHoursCopy.timeZone = value;

		_save(businessHoursCopy);
	}

	function handleEndChange(id: IDayID, index: number, value: string) {
		const businessHoursCopy = copy(businessHours);
		businessHoursCopy.weekly[id][index].end = value;

		_save(businessHoursCopy);
	}

	function handleStartChange(id: IDayID, index: number, value: string) {
		const businessHoursCopy = copy(businessHours);
		businessHoursCopy.weekly[id][index].start = value;

		_save(businessHoursCopy);
	}

	function handleAddClick(id: IDayID) {
		const businessHoursCopy = copy(businessHours);
		const newInterval = computeNewInterval(businessHoursCopy.weekly[id][businessHoursCopy.weekly[id].length - 1]);
		businessHoursCopy.weekly[id].push(newInterval);

		_save(businessHoursCopy);
	}

	function handleDeleteClick(id: IDayID, index: number) {
		const businessHoursCopy = copy(businessHours);

		businessHoursCopy.weekly[id] = businessHoursCopy.weekly[id].filter((e, i) => i !== index);

		_save(businessHoursCopy);
	}

	/**
	 * Replicates the same interval every day of the week.
	 */
	function handleCopyToAll(daySchedule: IBusinessHour[]) {
		const businessHoursCopy = copy(businessHours);

		Object.keys(businessHoursCopy.weekly).forEach((key) => {
			if (key !== 'SUNDAY' && key !== 'SATURDAY') {
				businessHoursCopy.weekly[key as IDayID] = [...daySchedule];
			}
		});

		_save(businessHoursCopy);
	}

	function _save(bh: IBusinessHours) {
		if (businessHoursService.checkBusinessHoursValid(bh)) {
			setBusinessHours(bh);
			props.onChange && props.onChange(bh);
		}
	}

	return {
		businessHours,
		handleAddClick,
		handleDeleteClick,
		handleEndChange,
		handleStartChange,
		handleTimeZoneChange,
		handleCopyToAll,
	};
}

function computeNewInterval(lastInterval?: IBusinessHour): IBusinessHour {
	if (!lastInterval) {
		return { start: '08:00', end: '14:00' };
	}

	const [lastEndHours, lastEndMinutes] = lastInterval.end.split(':').map(parseFloat);

	return {
		start: `${String((lastEndHours + 1) % 24).padStart(2, '0')}:${String(lastEndMinutes).padStart(2, '0')}`,
		end: `${String((lastEndHours + 2) % 24).padStart(2, '0')}:${String(lastEndMinutes).padStart(2, '0')}`,
	};
}
