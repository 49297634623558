import { END_UPDATE_MESSAGE } from '@/hooks/useBookingPagePreview';

/**
 * Función para actualizar el iframe que muestra la preview de disponibilidad.
 */
export function updatePreview() {
	const iframe = document.getElementById('booking-page-preview') as HTMLIFrameElement;
	if (iframe) {
		iframe.contentWindow?.postMessage({ type: END_UPDATE_MESSAGE });
	}
}

export default {
	updatePreview,
};
