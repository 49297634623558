import calendarService from '@/services/calendar/calendar.service';
import ITimeZone from '@/types/ITimeZone';
import { Temporal } from 'temporal-polyfill';

export function isWeekend(date: Date) {
	return date.getDay() === 0 || date.getDay() === 6;
}

/**
 *  Esta función está mal.
 *  Hay que especificar la zona horaria de "Hoy"
 */
export function isToday(date: Date) {
	return date.toDateString() === new Date().toDateString();
}

export function isInThePast(date: Date) {
	const year1 = date.getFullYear();
	const year2 = new Date().getFullYear();

	if (year1 < year2) {
		return true;
	}

	if (year1 > year2) {
		return false;
	}

	const month1 = date.getMonth();
	const month2 = new Date().getMonth();

	if (month1 < month2) {
		return true;
	}

	if (month1 > month2) {
		return false;
	}

	const day1 = date.getDate();
	const day2 = new Date().getDate();

	if (day1 < day2) {
		return true;
	}
	return false;
}

export function isSameDay(d1: Date, d2: Date, timeZone: ITimeZone): boolean {
	// Convert JavaScript Date objects to Temporal Instant
	const instant1 = Temporal.Instant.fromEpochMilliseconds(d1.getTime());
	const instant2 = Temporal.Instant.fromEpochMilliseconds(d2.getTime());

	// Convert the Temporal Instant to Temporal PlainDateTime using the given time zone
	const plainDateTime1 = instant1.toZonedDateTimeISO(timeZone).toPlainDate();
	const plainDateTime2 = instant2.toZonedDateTimeISO(timeZone).toPlainDate();

	// Compare the two dates
	return plainDateTime1.equals(plainDateTime2);
}

export function isCurrentMonth(d1: Date, timeZone: ITimeZone) {
	const f1 = calendarService.formatDate({
		date: d1,
		locale: 'en-US',
		options: {
			year: 'numeric',
			month: 'numeric',
			timeZone,
		},
	});

	const f2 = calendarService.formatDate({
		date: new Date(),
		locale: 'en-US',
		options: {
			year: 'numeric',
			month: 'numeric',
			timeZone,
		},
	});

	return f1 === f2;
}

export function minPlainDate(date1: Temporal.PlainDate, date2: Temporal.PlainDate): Temporal.PlainDate {
	if (Temporal.PlainDate.compare(date1, date2) <= 0) {
		return date1;
	} else {
		return date2;
	}
}

/**
 *
 */
export function maxPlainDate(date1: Temporal.PlainDate, date2: Temporal.PlainDate): Temporal.PlainDate {
	if (Temporal.PlainDate.compare(date1, date2) >= 0) {
		return date1;
	} else {
		return date2;
	}
}

export default { isToday, isInThePast, isWeekend, isSameDay, isCurrentMonth };
