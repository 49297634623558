export const LSK_SIDEBAR_VISIBILITY = 'tt_sidebar_visibility';

/**
 * Used to store the booker email and prefill the form.
 */
export const LSK_BOOKER_EMAIL = 'tt_booker_email';

/**
 * Used to control what is the default view mode in the calendar.
 * When given in the url it will take priority.
 * Otherwise we will use the one from localStorage.
 */
export const LSK_CALENDAR_VIEW_MODE = 'tt_calendar_view_mode';
