import { IDay } from '@/services/calendar/calendar.service';
import ITimeZone from '@/types/ITimeZone';
import { Button } from '@chakra-ui/react';

interface ICalendarDayProps {
	onClick: (day: IDay) => void;
	date: Date;
	isCurrentMonth: boolean;
	isToday: boolean;
	isWeekend: boolean;
	isSelected: boolean;
	isDisabled: boolean;
	isInThePast: boolean;
	timeZone: ITimeZone;
	day: IDay;
}

export function CalendarDay(props: ICalendarDayProps) {
	return (
		<Button
			alignSelf="center"
			backgroundColor={props.isDisabled ? 'transparent' : props.isSelected ? 'primary.800' : 'primary.50'}
			borderColor={props.isToday ? 'primary.500' : 'none'}
			borderRadius="full"
			borderWidth={props.isToday ? 2 : 'none'}
			color={props.isSelected ? 'white' : props.isDisabled ? 'gray.400' : 'primary.600'}
			colorScheme="primary"
			cursor={props.isDisabled ? 'not-allowed' : 'pointer'}
			disabled={props.isDisabled ? true : false}
			fontWeight={props.isSelected ? 700 : 700}
			fontSize="sm"
			h={{
				base: 10,
				md: 12,
			}}
			isDisabled={props.isDisabled}
			key={props.date.getTime()}
			onClick={() => props.onClick(props.day)}
			opacity={props.isDisabled ? 0.7 : 1}
			textDecoration={props.isInThePast ? 'line-through' : 'none'}
			title={props.date.toISOString()}
			visibility={props.isCurrentMonth ? 'visible' : 'hidden'}
			w={{
				base: 10,
				md: 12,
			}}
			_hover={{
				backgroundColor: props.isDisabled ? 'transparent' : props.isSelected ? 'primary.200' : 'primary.100',
			}}
		>
			{props.day.dt.day}
		</Button>
	);
}
