import { Flex, Tag, TagCloseButton, TagLabel } from '@chakra-ui/react';

interface IEMailsProps {
	emails: string[];
	onCloseClick: (email: string) => void;
}

export default function Emails(props: IEMailsProps) {
	return (
		<Flex flexDir="row" wrap="wrap" maxW="100%">
			{props.emails.map((email) => (
				<Tag
					my={1}
					mr={2}
					key={email}
					borderRadius="md"
					variant="solid"
					colorScheme={_getColorScheme(email)}
				>
					<TagLabel>{email}</TagLabel>
					<TagCloseButton
						aria-label={`Delete ${email}`}
						onClick={() => {
							props.onCloseClick(email);
						}}
					/>
				</Tag>
			))}
		</Flex>
	);
}
const EMAIL_REGEXP = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

function _getColorScheme(value: string): 'green' | 'red' {
	if (EMAIL_REGEXP.test(value)) {
		return 'green';
	}
	return 'red';
}
