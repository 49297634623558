import { LanguageSelect } from '@/components/language-select/language-select.view';
import { ILocale } from '@/constants/locales';
import calendarService, { IDay } from '@/services/calendar/calendar.service';
import ITimeZone from '@/types/ITimeZone';
import ISlot from '@/types/Slot';
import { Box, Button, Heading, HStack } from '@chakra-ui/react';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';
import Calendar from './components/Calendar/Calendar';
import TimeZoneSelector from './components/TimeZoneSelector';

interface IMonthProps {
	onDayClick: (day: IDay | null) => void;
	onNextClick: () => void;
	onPrevClick: () => void;
	onTimeZoneChange: React.ChangeEventHandler<HTMLSelectElement>;
	onLocaleChange: (value: ILocale) => void;
	availableLocales: Array<ILocale>;
	locale: ILocale;
	month: number;
	selectedDay: DateTime | undefined;
	slots: Array<ISlot>;
	timeZone: ITimeZone;
	year: number;
}

export default function Month(props: IMonthProps) {
	const { t } = useTranslation();

	return (
		<>
			<Box
				display={{
					base: props.selectedDay ? 'grid' : 'none',
					lg: 'none',
				}}
				px={6}
				py={4}
				borderWidth={1}
				borderColor="gray.200"
				borderRadius="sm"
				gridTemplateColumns="1fr auto"
				gap={2}
				alignItems="center"
			>
				<Heading size="sm">
					{calendarService.formatDate({
						date: props.selectedDay?.toJSDate() || new Date(),
						locale: props.locale,
						options: {
							weekday: 'long',
							day: '2-digit',
							month: 'long',
						},
					})}
				</Heading>

				<Button size="sm" colorScheme="primary" onClick={() => props.onDayClick(null)}>
					{t('Change day')}
				</Button>
			</Box>

			<Box
				display={{
					base: props.selectedDay ? 'none' : 'block',
					lg: 'grid',
				}}
				h="full"
				py={6}
				px={{ base: 2, sm: 4, md: 6, lg: 8 }}
				borderBottom={{ base: '4px solid var(--chakra-colors-primary-500)', lg: 'none' }}
			>
				<HStack mb={4} ml="auto" w={{ base: 'sm' }} gap={2}>
					<TimeZoneSelector size="xs" value={props.timeZone} onChange={props.onTimeZoneChange} />
					<LanguageSelect
						availableValues={props.availableLocales}
						size="xs"
						label={''}
						value={props.locale}
						onChange={(v) => props.onLocaleChange(v.value)}
					/>
				</HStack>
				<Calendar
					timeZone={props.timeZone}
					selected={props.selectedDay?.toJSDate()}
					slots={props.slots}
					locale={props.locale}
					onPrev={props.onPrevClick}
					onNext={props.onNextClick}
					month={props.month}
					year={props.year}
					onDayClick={props.onDayClick}
				/>
			</Box>
		</>
	);
}
