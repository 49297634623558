import { LanguageSelect } from '@/components/language-select/language-select.view';
import { ILocale } from '@/constants/locales';
import ITimeZone from '@/types/ITimeZone';
import { Box } from '@chakra-ui/react';
import { t } from 'i18next';
import TimeZoneSelector from './components/time-zone-selector.view';

interface IProps {
	availableValues: Array<ILocale>;
	locale: ILocale;
	timeZone: ITimeZone;
	onTimezoneChange: (value: ITimeZone) => void;
	onLocaleChange: (value: ILocale) => void;
}

export default function BookingPageFooter(props: IProps) {
	return (
		<Box
			data-id="booking-page-settings"
			px={{ base: 8, lg: 0 }}
			display="grid"
			gridTemplateColumns="1fr 1fr"
			py={4}
			alignItems="center"
			gap={4}
		>
			<TimeZoneSelector size="xs" value={props.timeZone} onChange={(value) => props.onTimezoneChange(value)} />
			<LanguageSelect
				availableValues={props.availableValues}
				size="xs"
				label={t('Language')}
				value={props.locale}
				onChange={(v) => props.onLocaleChange(v.value)}
			/>
		</Box>
	);
}
