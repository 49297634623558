import client from '@/api_client/client';
import IResource from '@/types/IResource';

interface IDetailsArgs {
	id: string;
}

export async function details(args: IDetailsArgs): Promise<IResource> {
	return client.get('/resources/' + args.id).then((res) => res.data);
}

export async function list(): Promise<{ resources: Array<IResource> }> {
	return client.get('/resources').then((res) => res.data);
}

type ICreateResourceArgs = Omit<IResource, 'owner'>;

export async function create(args: ICreateResourceArgs): Promise<void> {
	return client.put(`/resources/${args.id}`, {
		...args,
		checkAvailabilityInCalendars: args.bookingRules.checkAvailabilityInCalendars || [],
	});
}

export async function remove(args: IDetailsArgs): Promise<void> {
	return client.delete('/resources/' + args.id).then((res) => res.data);
}

export default {
	create,
	details,
	list,
	remove,
};
