import {
	BOOKER_EMAIL_QUESTION_ID,
	BOOKER_NOTES_QUESTION_ID,
	BOOKER_PHONE_QUESTION_ID,
	BOOKING_START_QUESTION_ID,
} from '@/constants/constants';
import { LSK_BOOKER_EMAIL } from '@/constants/storage-keys';
import { useSearchParams } from '@/hooks/useSearchParams';
import IQuestion, { ILocationOption } from '@/types/IQuestion';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

interface IUseBookingFormParams {
	questions: Array<IQuestion>;
	start: Date;
	end: Date;
	onBookingConfirmed: (params: { answers: Record<string, string> }) => void;
	onBackClick: () => void;
}
export default function useBookingForm(params: IUseBookingFormParams) {
	const { t } = useTranslation();

	const searchParams = useSearchParams();

	const form = useForm({
		defaultValues: _getInitialValues({
			questions: params.questions,
			start: params.start,
			valuesGivenOnTheUrl: _getValuesGivenOnTheUrl(searchParams),
		}),
		mode: 'all',
	});

	const handleSubmit = form.handleSubmit((answers) => {
		/**
		 * Store user email in localStorage so we can autoFill or later
		 */
		localStorage.setItem(LSK_BOOKER_EMAIL, answers[BOOKER_EMAIL_QUESTION_ID]);
		return params.onBookingConfirmed({ answers });
	});

	return {
		handleSubmit,
		t,
		form,
	};
}

interface IGetInitialValuesParams {
	questions: Array<IQuestion>;
	start: Date;
	valuesGivenOnTheUrl: Record<string, string>;
}

function _getInitialValues(args: IGetInitialValuesParams): Record<string, string> {
	type K = keyof typeof initialValues;
	// TODO: At the moment those fields are always required but they don't comme in the question array.
	const initialValues: Record<string, string> = {
		[BOOKER_NOTES_QUESTION_ID]: '',
		[BOOKING_START_QUESTION_ID]: args.start.toISOString(),
	};

	args.questions.forEach((question) => {
		initialValues[question.id as K] = '';
		// If there is a location question with the Booker phone option enabled create a phone field
		if (
			question.type === 'LOCATION' &&
			question.allowedOptions.some((e: ILocationOption) => e.type === 'BookerPhoneLocationOption')
		) {
			initialValues[question.id as K] = 'BookerPhoneLocationOption';
			initialValues[BOOKER_PHONE_QUESTION_ID as K] = '';
		}

		/**
		 * If possible prefill the email with some value stored from localStorage
		 */
		if (question.id === BOOKER_EMAIL_QUESTION_ID) {
			const initialEmailValue = localStorage.getItem(LSK_BOOKER_EMAIL);
			if (initialEmailValue) {
				initialValues[BOOKER_EMAIL_QUESTION_ID] = initialEmailValue;
			}
		}
	});

	// Override initial values with those given in the url
	Object.keys(args.valuesGivenOnTheUrl).forEach((key) => {
		if (initialValues[key] === '') {
			initialValues[key] = args.valuesGivenOnTheUrl[key];
		}
	});

	return initialValues;
}

/**
 * Returns an object with the search params given in the url that starts with ttq[
 */
function _getValuesGivenOnTheUrl(urlSearchParams: URLSearchParams) {
	const params = Object.fromEntries(urlSearchParams.entries());
	const valuesGivenOnTheUrl = Object.keys(params).reduce((acc: Record<string, string>, key) => {
		if (key.startsWith('ttq[')) {
			// Get value between brackets ID using a regex:
			const id = key.match(/\[(.*?)\]/)?.[1];
			if (id) {
				acc[id] = params[key];
			}
		}
		return acc;
	}, {});

	return valuesGivenOnTheUrl;
}
