const colors = {
	pink: {
		'50': '#f5c0d7',
		'100': '#ed94bc',
		'200': '#ea7eae',
		'300': '#e669a0',
		'400': '#e25393',
		'500': '#db2777',
		'600': '#b11e5f',
		'700': '#9c1a54',
		'800': '#861648',
		'900': '#5a0f30',
	},
	red: {
		'50': '#fdf6f5',
		'100': '#f8d9d7',
		'200': '#f2b7b4',
		'300': '#eb8b86',
		'400': '#e6716b',
		'500': '#df463e',
		'600': '#c82c24',
		'700': '#a2231d',
		'800': '#891e18',
		'900': '#641612',
	},
	orange: {
		'50': '#fdfaf6',
		'100': '#f9ebda',
		'200': '#f2d4b0',
		'300': '#e7b271',
		'400': '#dd9235',
		'500': '#c37b23',
		'600': '#a5681d',
		'700': '#835317',
		'800': '#674112',
		'900': '#55360f',
	},
	yellow: {
		'50': '#fefefb',
		'100': '#fbf9ea',
		'200': '#f4eec0',
		'300': '#ece18e',
		'400': '#e0cd43',
		'500': '#bba921',
		'600': '#95871b',
		'700': '#746915',
		'800': '#574f10',
		'900': '#48410d',
	},
	green: {
		'50': '#f4fdf9',
		'100': '#c5f5e0',
		'200': '#7feaba',
		'300': '#27d888',
		'400': '#22bd77',
		'500': '#1da367',
		'600': '#188755',
		'700': '#136942',
		'800': '#0f5636',
		'900': '#0d472d',
	},
	teal: {
		'50': '#f0fcfd',
		'100': '#bef1f4',
		'200': '#80e5ea',
		'300': '#27d1da',
		'400': '#21b2ba',
		'500': '#1c979e',
		'600': '#177b80',
		'700': '#126064',
		'800': '#0f5053',
		'900': '#0c4244',
	},
	cyan: {
		'50': '#f4fbfd',
		'100': '#cfeef7',
		'200': '#bae7f3',
		'300': '#a0deef',
		'400': '#50c2e2',
		'500': '#27b4db',
		'600': '#23a2c5',
		'700': '#1d86a3',
		'800': '#186e86',
		'900': '#125568',
	},
	blue: {
		'50': '#f1f6fd',
		'100': '#cde0f7',
		'200': '#a8c8f0',
		'300': '#7fafea',
		'400': '#5796e3',
		'500': '#327fdd',
		'600': '#2269c0',
		'700': '#1a5193',
		'800': '#154279',
		'900': '#123662',
	},
	purple: {
		'50': '#f9f6fd',
		'100': '#e6daf9',
		'200': '#d3bef4',
		'300': '#b795ed',
		'400': '#a378e9',
		'500': '#8951e2',
		'600': '#7433dd',
		'700': '#5f22c1',
		'800': '#4f1c9f',
		'900': '#3b1577',
	},
	black: {
		50: '#f9f9fa',
		100: '#d9d9d9',
		200: '#bfbfbf',
		300: '#a6a6a6',
		400: '#8c8c8c',
		500: '#0d0d0d',
		600: '#595959',
		700: '#404040',
		800: '#262626',
		900: '#0d0d0d',
	},
	wine: {
		50: '#FAF5F0',
		100: '#F4EBE1',
		200: '#EAD8C2',
		300: '#DFC4A4',
		400: '#D5B086',
		500: '#CB9E69',
		600: '#B67E3F',
		700: '#885F2F',
		800: '#5B3F20',
		900: '#2D2010',
		950: '#171008',
	},
	zinc: {
		'50': '#f6f6f7',
		'100': '#efeff0',
		'200': '#e1e2e4',
		'300': '#cecfd3',
		'400': '#b9bac0',
		'500': '#a1a1aa',
		'600': '#91919a',
		'700': '#7d7d85',
		'800': '#66666d',
		'900': '#55555a',
		'950': '#323234',
	},
};

export default colors;
