import IApplicationConfig from '@/types/IApplicationConfig';
import React from 'react';

export interface IAppConfigContext extends IApplicationConfig {}

export default React.createContext<IAppConfigContext>({
	name: '',
	hasGoogleLogin: false,
	hasMicrosoftLogin: false,
	hasEmailAndPasswordLogin: false,
	hasGoogleCalendar: false,
	hasMicrosoftCalendar: false,
	theme: {
		ttColor: 'pink'
	}
});
