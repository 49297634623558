import { Box, Button, Container, Heading, Text, VStack } from '@chakra-ui/react';
import { t } from 'i18next';

interface IProps {
	onBackClick: () => void;
}

export default function NoAvailabilityInfo(props: IProps) {
	return (
		<Box id="booking-form" w="full">
			<Container maxW="container.md" py={20} px={8}>
				<VStack spacing={4} align="center" textAlign="center">
					<Box>
						<Heading fontWeight="bold">{t('No available spots left')}</Heading>

						<Text mt={8} color="gray.600">
							{t('Unfortunately, there is no availability at this time.')}
						</Text>
					</Box>

					<Button
						variant="link"
						colorScheme='blue'
						w="full"
						size={{ base: 'sm', md: 'md' }}
						type="button"
						onClick={props.onBackClick}
					>
						{t('Choose another time')}
					</Button>
				</VStack>
			</Container>
		</Box>
	);
}
